import React from 'react';
import PostCard from '/src/routes/latest/components/PostCard';
import SkeletonCard from '/src/components/SkeletonCard';
import parse from 'html-react-parser/dist/html-react-parser';

const bgBlob = new URL('../../../images/postBG_blob.png?as=webp&quality=45&width=2000', import.meta.url);

export default function PostsGrid({ posts, loaded, heading = '' }) {
    return (
        <div
            style={{ '--image-url': `url(${bgBlob})` }}
            className={`bg-foreground-100 py-11 md:py-16 md:bg-[image:var(--image-url)] bg-contain bg-no-repeat bg-[center_top_2rem]`}
        >
            <div className='container overflow-visible'>
                {heading && (
                    <h2 className='text-3xl md:text-4xl text-accent font-normal tracking-wide uppercase mb-10'>
                        {parse(heading)}
                    </h2>
                )}

                <div className='grid gap-6 md:grid-cols-2 lg:grid-cols-3 justify-center'>
                    {posts
                        ? posts.map((post, i) => {
                              return loaded ? (
                                  <div key={`post-${post.id}`}>
                                      <PostCard post={post} />
                                  </div>
                              ) : (
                                  <SkeletonCard lines={8} key={i} />
                              );
                          })
                        : null}
                </div>
            </div>
        </div>
    );
}
