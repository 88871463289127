import React, { useEffect, useState } from 'react';
import './PageLead.css';

export default function PageLead(props) {
    const [loaded, setLoaded] = useState(false);
    const loadedClass = 'opacity-100 translate-y-0';
    const unloadedClass = 'opacity-0 translate-y-8';

    const minHeroHeight = props.minHeight ? props.minHeight : 600;
    let featuredImageStyles = 'object-cover object-left-top'; // Default featuredImageStyles is currently unused, you can edit them!
    if (props.style == 'featured-full-bot') {
        featuredImageStyles = 'absolute bottom-0 left-0 w-full min-h-1/2screen object-cover z-0 select-none';
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={`relative z-20 py-8 lg:py-20 lg:pb-0 min-h-[${minHeroHeight}]`}>
            <div className={`relative container py-8 mb-8 z-10 ${props.align === 'left' ? 'text-left' : 'text-center'}`}>
                <div className={`${props.noMaxWidth !== true && 'max-w-prose'} ${props.align !== 'left' && 'mx-auto'}`}>
                    <div className={`duration-300 ease-in ${loaded ? loadedClass : unloadedClass}`}>
                        {props.title && (
                            <h1 className={`mb-4 font-display font-normal text-sm md:text-2xl uppercase tracking-widest${props.titleVisible ? '' : ' hidden'}`}>
                                {props.title}
                            </h1>
                        )}

                        {props.subtitle && (
                            <h2 className={'mb-5 font-serif !leading-tight font-normal ' + (props.bg === 'white' ? 'text-background-500 text-4xl md:text-5xl ' : 'text-accent text-5xl md:text-7xl ')}>{props.subtitle}</h2>
                        )}
                    </div>
                    <div className={`duration-200 ease-in delay-75 ${loaded ? loadedClass : unloadedClass}`}>
                        {props.text && (
                            <div
                                className={`
                                    intro leading-8
                                    ${props.noMaxWidth === true && '[&>p]:text-xl'}
                                    ${props.bg === 'white' ? 'text-sm md:text-base max-w-prose mx-auto text-background-500' : 'text-lg md:text-xl'}
                                `}
                                dangerouslySetInnerHTML={{ __html: props.text }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {props.image && props.image.indexOf("undefined") === -1 && (
                <img
                    src={`${props.image}&w=1920`}
                    alt=''
                    className={`${featuredImageStyles}`}
                    draggable='false'
                />
            )}
        </div>
    );
};
