import { useState } from 'react';
import ContactNotification from '../../../components/ContactNotification';
import TextInput from '../../../components/forms/textInput';
import Checkbox from '../../../components/forms/checkbox';
import Button from '../../../components/button';
import axios from 'axios';
import { getAPI } from '../../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ContactForm(props) {
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [sending, setSending] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', success: null });
    const [fieldErrors, setFieldErrors] = useState({});
    const apiUrl = getAPI();
    const connection = `${apiUrl}/wp-json/gf/v2/forms/${props.formID ? props.formID : '3'}/submissions`;

    const inputDefaults = {
        'input_1.3': '',
        'input_1.6': '',
        'input_3': '',
        'input_5': '',
        'input_6.1': 'You agree to our friendly privacy policy.',
    };

    const [formData, setFormData] = useState(inputDefaults);
    const file = props.file ? props.file : null;

    const handleNotification = (success) => {
        let message = success
            ? '<p className="font-display">Thank you for your interest. Your report will begin downloading!</p>'
            : '<p>Oops! Something went wrong. Please try again later.</p>';

        // Trigger PDF download on submit
        if (success) {
           downloadPDF(file);
        }

        setNotification({
            ...notification,
            show: true,
            message: message,
            success: success,
        });

        setTimeout(() => {
            setNotification({ ...notification, show: false });
        }, 5000);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSending(true);
        makeRequest();
    };

    const handleError = (err) => {
		const message = err.message;
		const fieldErrs = err.response?.data?.validation_messages;
        if (err) {
            setError(message);
			console.log(message);
        }
		if (fieldErrs) {
            setFieldErrors(fieldErrs);
        } else {
            handleNotification(false);
        }
    };

    async function downloadPDF(file) {
        if(file) {
            // Get tthe data of the file upload to find it in the WP Media Library
            let fileDateArray = file.date.split('-');
            let fileUploadYear = fileDateArray[0];
            let fileUploadMonth = fileDateArray[1];
            let fileUploadName = file.filename;

            // Build the WP file source url
            const wpFileSource = `${fileUploadYear}/${fileUploadMonth}/${fileUploadName}`;
            const url = `${apiUrl}/wp-content/uploads/${wpFileSource}`;

            fetch(url)
            .then( res => res.blob() )
            .then( blob => {
                var fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = `${file.filename}`;
                alink.click();
            });
        }
    }

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: connection,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic YXJjaGVyLWFkbWluOjd4MTcgUU9ZaCBCdUo5IFNBZk8gclBEUSAyVm9j',
        },
        data: JSON.stringify(formData),
    };

    async function makeRequest() {
        try {
            const response = await axios.request(config);
            setSending(false);
            setResponse(response.data);
            handleNotification(true);
        } catch (error) {
            setSending(false);
            handleError(error);
        }
    }

    return (
        <>
            <form
                className='
                flex h-full flex-col
                bg-accent
                dark:bg-background-600'
                action={connection}
                method='post'
                onSubmit={handleSubmit}
            >
                <div className='h-0 flex-1 overflow-y-auto'>
                    {/* Header */}
                    <div className='px-4 py-6 sm:px-6 bg-accent dark:bg-background-700 text-center'>
                        <div className='mt-1'>
                            <h3 className='text-4xl md:text-5xl text-white'>
                                <em>Download the research report</em>
                            </h3>
                        </div>
                    </div>
                    {/* Body */}
                    <div className='flex flex-1 flex-col justify-between'>
                        <div className='divide-y divide-gray-200 px-4 sm:px-6 text-white'>
                            <div className='space-y-6 pb-5 pt-6'>
                                <div className='grid grid-rows-1 sm:grid-cols-2 gap-4'>
									<div className='[&>div>p]:text-white [&>div>div>input]:py-3'>
										<TextInput
											required
											label='First name'
											placeholder='First name'
											name='input_1.3'
											value={formData['input_1.3']}
											onChange={handleChange}
											hasError={Object.hasOwn(fieldErrors, '1')}
											error={fieldErrors[1]}
										/>
									</div>
									<div className='[&>div>p]:text-white [&>div>div>input]:py-3'>
										<TextInput
											required
											label='Last name'
											aria-label='Last name'
											placeholder='Last name'
											name='input_1.6'
											value={formData['input_1.6']}
											onChange={handleChange}
											hasError={Object.hasOwn(fieldErrors, '1')}
										/>
									</div>
                                </div>

                                <div className='grid grid-rows-1 gap-4'>
                                    <div className='[&>div>p]:text-white [&>div>div>input]:py-3'>
                                        <TextInput
                                            required
                                            label='Company'
                                            placeholder='Company'
                                            name='input_3'
                                            value={formData.input_3}
                                            onChange={handleChange}
                                            hasError={Object.hasOwn(fieldErrors, '3')}
                                            error={fieldErrors[3]}
                                        />
                                    </div>
                                </div>

                                <div className='grid grid-rows-1 gap-4'>
                                    <div className='[&>div>p]:text-white [&>div>div>input]:py-3'>
                                        <TextInput
                                            required
                                            label='Email'
                                            placeholder='Email'
                                            name='input_5'
                                            type='email'
                                            value={formData.input_5}
                                            onChange={handleChange}
                                            hasError={Object.hasOwn(fieldErrors, '5')}
                                            error={fieldErrors[5]}
                                        />
                                    </div>
                                </div>

                                <div className='grid grid-rows-1 gap-4'>
                                    <div>
                                        <Checkbox
                                            required
                                            label='You agree to our friendly privacy policy.'
                                            name='input_6.1'
                                            checked={formData['input_6.1']}
                                            onChange={handleChange}
                                            hasError={Object.hasOwn(fieldErrors, '6')}
                                            error={fieldErrors[6]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <div className='flex flex-shrink-0 justify-end px-4 py-4 gap-2 bg-accent'>
                    <button type='submit' className='btn btn-accent border-white border text-xs !text-white'>
                        Download{' '}
                        {sending && (
                            <FontAwesomeIcon icon={faSpinner} spinPulse className='text-white w-4 h-4 ml-2' />
                        )}
                    </button>
                </div>
            </form>

            <ContactNotification data={notification} />
        </>
    );
}
