/**
 * Block | Content Block
 * 
 * @param
 * text_columns
 * Contains an array of WYSIWYG content from ACF for each column.
 * 
 * heading
 * optional section title that appears above the columns
 */

import React from 'react';
import { motion } from 'framer-motion';

export default function CSContent({ heading, text_columns, lastItem }) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className={`block__content
            bg-foreground-100
            relative overflow-hidden
            py-8 md:py-12
            `}
        >
            <div className='container max-w-7xl relative z-10'>
                <h2 className='tracking-wide mb-5'>
                    {heading}
                </h2>
                <div className={`grid lg:grid-flow-col lg:grid-cols-${text_columns.length} gap-x-10 ${text_columns.length === 1 ? 'text-center [&>div]:mx-auto [&>div]:max-w-5xl' : ''}`}>
                {text_columns && (text_columns.map((colContent, index) => {
                    return(
                    <div key={index}
                        className='prose prose-lg not-prose pt-6 [&>p]:text-xl [&>ul]:text-xl lg:[&>p]:leading-8'
                        dangerouslySetInnerHTML={{ __html: colContent.column }}
                    />
                    )
                }))}
                </div>
            </div>
        </motion.section>
    )
}
