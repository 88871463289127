import parse, { domToReact } from 'html-react-parser/dist/html-react-parser';

 export default function Checkbox(props) {

  // Insert the policy privacy link if label text matches
  let linkedLabel = '';
  if (JSON.stringify(props.label) === '"You agree to our friendly privacy policy."') {
    linkedLabel = 'You agree to our friendly <a href="/privacy-policy" target="_blank" class="underline">privacy policy</a>.'
  }

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={props.name}
          aria-describedby={props.label}
          name={props.name}
          type="checkbox"
          className="
            h-5
            w-5
            rounded
            text-accent
            border-background-200
            focus:ring-accent
            dark:border-background-400
            dark:focus:ring-background-400
            dark:focus:ring-offset-background-700
            dark:bg-background-800
          "
          required={props.required}
          value={props.checked}
          onChange={props.onChange}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={props.name} className="font-medium dark:text-foreground">
          {linkedLabel ? parse(linkedLabel) : props.label}
        </label>{' '}
      </div>
    </div>
  )
}
