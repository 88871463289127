import Button from '../../components/button';
import Seo from '../../components/Seo';
import { Link } from 'react-router-dom';
export default function Error404(props) {
    return (
        <>
            <Seo title='404 Page not found' />
            <div className='text-xl text-center text-white h-screen flex flex-col fixed inset-0 z-[999] items-center justify-center'>
                <h1 className='text-4xl'>404 Page not found</h1>
                <Link to='/'>
                    <Button className='mt-8'>Go back home</Button>
                </Link>
            </div>
        </>
    );
}
