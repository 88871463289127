import React from 'react';
// import Featured from './Featured';
import FilterGrid from './FilterGrid';
import api from '/src/utils/api';
import makePosts from '/src/utils/makePostsWork';

export default function WorkPageContent() {
    // const [featured, setFeatured] = React.useState(null);
    const [posts, setPosts] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        api.getData('case-study?per_page=99&orderby=menu_order&_embed&acf_format=standard')
            .then((response) => {
                // console.log(response.data)
                setPosts(makePosts(response.data));
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

    // console.log(posts);
    return loaded && <FilterGrid posts={posts} loaded={loaded} />;
}
