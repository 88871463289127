import React, { useState, useEffect, useRef } from 'react';

export default function Video({ videoSource, placeholderImage }) {
    const [source, setSource] = useState(false);
    const [isIframe, setIsIframe] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showPlaceholder, setShowPlaceholder] = useState(true);
    const videoRef = useRef(null);

    const handlePlaceholderClick = () => {
        if (source) {
            if (!isIframe)
                videoRef.current.play();
            setIsPlaying(true);
            setShowPlaceholder(false);
        }
    }

    const handleVideoClick = () => {
        if (isPlaying) {
            videoRef.current.pause();
            setIsPlaying(false);
        } else {
            videoRef.current.play();
            setIsPlaying(true);
        }
    }

    useEffect(() => {
        if (videoSource) {
            if (videoSource.includes('youtube.com') || videoSource.includes('youtu.be')) {
                // Extract the YouTube video ID from the URL
                const videoId = videoSource.match(/(?:\?v=|\/embed\/|\.be\/)([\w\-]+)/)[1];
                setSource(`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`);
                setIsIframe(true);
            } else if (videoSource.includes('vimeo.com')) {
                // Extract the Vimeo video ID from the URL
                const videoId = videoSource.match(/vimeo.com\/(?:video\/|)(\d+)/)[1];
                setSource(`https://player.vimeo.com/video/${videoId}?autoplay=1`);
                setIsIframe(true);
            } else {
                // It's a self hosted video file
                setSource(videoSource);
            }
        }
    }, [videoSource]);

    return (
    <div className={`relative w-full max-w-7xl h-auto aspect-video mx-auto my-4 
                    ${videoSource ? 'cursor-pointer' : ''}`}>

        {placeholderImage && showPlaceholder && (
        <img
            onClick={handlePlaceholderClick}
            src={placeholderImage['url']}
            alt={placeholderImage['alt']}
            className='absolute inset-0 w-full h-full object-cover z-20'
        />
        )}

        {isIframe && placeholderImage && isPlaying ? (
        <iframe
            src={source}
            className='absolute inset-0 w-full h-full z-10'
            allow='autoplay; encrypted-media'
            allowFullScreen
        />
        ) : null}

        {isIframe && !placeholderImage ? (
        <iframe
            src={source}
            className='absolute inset-0 w-full h-full z-10'
            allow='encrypted-media'
            allowFullScreen
        />
        ) : null}

        {source && !isIframe && (
        <video
            ref={videoRef}
            onClick={handleVideoClick}
            className='absolute inset-0 w-full h-full object-cover z-10'
        >
            <source src={source} type='video/mp4' />
        </video>
        )}
        
    </div>
    );
};