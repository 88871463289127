export const formLabel = `block text-sm font-medium leading-6 dark:text-background-100`
export const errorLabel = `block text-sm font-medium leading-6 dark:text-background-100`
export const inputBorderSurface = `
  block
  w-full
  rounded-md
  border-0
  py-1.5
  shadow-sm
  ring-1
  ring-inset
  duration-200
  focus:ring-2
  focus:ring-inset
  outline-none
  sm:text-sm
  sm:leading-6
  text-left
  pl-3
`
export const inputBorder = `
  ${inputBorderSurface}
  placeholder-background-100              dark:placeholder-background-400
  ring-background-200                     dark:ring-background-400
  text-background-900                     dark:text-background-100
  focus:ring-accent                       dark:focus:ring-accent-600
  bg-foreground-100                       dark:bg-background-700
`
export const inputBorderError = `
  ${inputBorderSurface}
  placeholder-red-100                     dark:placeholder-background-400
  ring-red-200                            dark:ring-red-500
  text-red-900                            dark:text-white
  focus:ring-red                          dark:focus:ring-red-500
  bg-red-100                              dark:bg-background-700
`