/**
 * Block | Content Block
 * 
 * @param
 * accentImage
 * Ghost image used for case study pages, seperate from the featured image.
 * 
 * clientName
 * The client name which can be different from the case study page title
 * 
 * headline
 * Subtitle for the hero
 * 
 * description
 * Paragraph content for each hero
 */

import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion } from 'framer-motion';
import Starline from '../components/Starline';

export default function Hero({ accentImage, clientName, headline, description }) {
    const [heroLoaded, setHeroLoaded] = useState(false);

    const loadedClass = 'opacity-100 translate-y-0';
    const unloadedClass = 'opacity-0 translate-y-8';
    useEffect(() => {
        const timer = setTimeout(() => {
            setHeroLoaded(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <motion.section
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.2 }}
        layout
        className='relative transparent overflow-hidden'
        >
        <div className='relative sm:overflow-hidden pb-20 sm:pb-28 pt-44'>
            <div className='relative container pt-16 z-30'>
                <div className={`${accentImage ? 'max-w-4xl' : 'max-w-6xl'}`}>
                    <div className='mb-6 md:mb-0'>
                        <div
                            className={`duration-300 ease-in ${
                                heroLoaded ? loadedClass : unloadedClass
                            }`}
                        >
                            <h3 className='text-2xl text-foreground uppercase leading-8 tracking-widest mb-6 md:mb-4'>
                                {clientName && clientName}
                            </h3>
                            <h1
                                className={`text-5xl font-serif text-accent font-normal mb-5 leading-tight
                                            ${
                                                accentImage
                                                    ? 'max-w-2xl'
                                                    : 'max-w-3xl'
                                            }`}
                            >
                                {headline && parse(headline)}
                            </h1>
                            {description && (
                                <div
                                    className='text-lg sm:text-xl text-foreground-300 leading-8 lg:leading-10 [&>p]:mb-6'
                                    dangerouslySetInnerHTML={{ __html: description }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Starline opacity='20' />
        </div>
        {accentImage && (
            <div
                className={`duration-200 ease-in delay-300 ${
                    heroLoaded ? loadedClass : unloadedClass
                } absolute
                    hidden
                    xl:flex
                    items-end
                    bottom-0
                    -top-[20%]
                    2xl:top-0
                    left-2/3
                    2xl:left-1/2
                    h-full
                    select-none
                    z-20
                `}
            >
                <img
                    src={`${accentImage}&w=800`}
                    alt=''
                    className='object-cover object-left-top'
                    draggable='false'
                />
            </div>
        )}
        </motion.section>
    )
}
