import React from 'react';
import { useNavigate } from 'react-router-dom';
import SkeletonImage from '/src/components/SkeletonImage';
import SkeletonText from '/src/components/SkeletonText';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
const bgStar = new URL('../../../images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);

export default function Featured({ post, loaded }) {
    const navigate = useNavigate();
    const toPost = () => {
        navigate(`/latest/${post.slug}/`, { state: post });
    };

    return (
        <div
            style={{ '--image-url': `url(${bgStar})` }}
            className='bg-white
            relative overflow-hidden
            md:before:block
            before:absolute
            before:bg-[image:var(--image-url)]
            before:bg-contain
            before:bg-no-repeat
            before:inset-x-1/2
            before:-translate-y-1/2
            before:-translate-x-1/2
            before:top-0
            before:opacity-80

            before:w-[425]
            before:h-[425]'
        >
            <div className='container py-11 md:pt-16 md:pb-4 lg:pt-32 relative z-10'>
                <div className='flex flex-col md:flex-row gap-8'>
                    <div className='max-w-xl aspect-photo overflow-hidden'>
                        {loaded ? (
                            <img className='object-cover object-center' src={post.image} alt={post.image_alt} role={post.image_alt ? 'img' : 'presentation'} />
                        ) : (
                            <SkeletonImage />
                        )}
                    </div>
                    <div>
                        {loaded ? (
                            <div>
                                <span className='block font-bold text-sm text-foreground-800 mb-4'>
                                    { post.externalAuthor && post.author === 'Media Publication' ? post.externalAuthor : post.author } • {post.date}
                                </span>
                                <h2 className='text-accent text-3xl font-normal tracking-wide mb-4'>{post.title}</h2>
                                <div
                                    className='text-foreground-700 text-lg'
                                    dangerouslySetInnerHTML={{ __html: post.excerpt }}
                                ></div>
                                <button
                                    role='button'
                                    onClick={toPost}
                                    className='mt-6 flex items-center stretched-link hover:text-background transition-colors group'
                                    aria-label={`View post: ${post.title}`}
                                >
                                    Read post <ArrowUpRightIcon className='w-5 h-5 ml-2 text-accent group-hover:text-background transition-colors group-hover:animate-pulse' />
                                </button>
                            </div>
                        ) : (
                            <SkeletonText />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
