import React, { useState, useEffect } from 'react';
const bgBlob = new URL('/src/images/postBG_blob.png?as=webp&quality=46&width=2000', import.meta.url);
import api from '/src/utils/api';
import LeaderCard from './LeaderCard';
import { motion } from 'framer-motion';

export default function LeadersSection({ posts }) {
    const [leadershipArray, setLeadershipArray] = useState(null);

    useEffect(() => {
        api.getData(`authors/?include=${posts}&orderby=include&acf_format=standard&per_page=99`)
            .then((response) => {
                setLeadershipArray(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [posts]);

    const makeLeader = (item) => {
        return {
            id: item.id,
            name: item.title.rendered,
            title: item.acf.author_job_title,
            social: item.acf.author_social[0].asm_link,
            image: {
                default: item.acf.headshot.url,
                // hover: item.acf.headshot_hover.url,
            },
        };
    };
    return (
        leadershipArray && (
            <motion.section
                initial={{ opacity: 0, y: 40, rotate: 2 }}
                animate={{ opacity: 1, y: 0, rotate: 0 }}
                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                layout
                style={{ '--image-url': `url(${bgBlob})` }}
                className={`bg-foreground-100 pb-24 md:pb-28 md:bg-[image:var(--image-url)] bg-contain bg-no-repeat bg-bottom`}
            >
                <div className='container overflow-visible'>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 gap-y-10 lg:gap-y-20 not-prose text-pretty'>
                        {leadershipArray.map((item, index) => {
                            const leader = makeLeader(item);
                            return <LeaderCard leader={leader} key={index} />;
                        })}
                    </div>
                </div>
            </motion.section>
        )
    );
}
