import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { formLabel, inputBorder, errorLabel, inputBorderError } from './styles'
import InputWrapper from './inputWrapper'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ListBox(props) {

  return (
    <Listbox value={props.value} onChange={props.onChange} name={props.name}>
      {({ open }) => (
        <>
          <InputWrapper {...props}>
          <div className="relative">
            <Listbox.Button className={props.hasError ? inputBorderError : inputBorder}>
              <span className="block truncate">{props.value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={({active}) =>
                  classNames(
                    active ? 'bg-background-600 text-foreground-100' : 'text-foreground',
                    'absolute z-10 mt-1 w-full overflow-auto rounded-md bg-background-500 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                )}>
                {props.items.map((item, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-background-600 text-foreground-100' : 'text-foreground',
                        'relative cursor-default select-none py-2 pl-8 pr-4'
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {item}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-accent',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          </InputWrapper>
        </>
      )}
    </Listbox>
  )
}
