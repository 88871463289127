import { useState } from 'react';
import Button from './button';
import ContactNotification from './ContactNotification';
import ContactFormDrawer from './ContactFormDrawer';
import ListBox from './forms/listbox';
import TextInput from './forms/textInput';
import TextArea from './forms/textArea';
import Checkbox from './forms/checkbox';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from './button';
import axios from 'axios';
import { getAPI } from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ContactForm(props) {
    let [open, setOpen] = useState(false);
	const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
	const [sending, setSending] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', success: null });
    const [fieldErrors, setFieldErrors] = useState({});

	const apiUrl = getAPI();

    const options = ['General', 'New Business', 'Press', 'Careers'];

    if (props.open) {
        open = props.open;
        setOpen = props.setOpen;
    }
	const inputDefaults = {
        input_1: options[0],
        input_3: '',
        input_4: '',
        input_5: '',
        input_6: '',
        'input_7.1': false,
    }

    const [formData, setFormData] = useState(inputDefaults);

    const handleDrawer = (open) => {
        setOpen(open);
		if(!open) {
			setFormData(inputDefaults)
			setFieldErrors({})
		}
    };

    const handleNotification = (success) => {
        handleDrawer(false);
        let message = success
            ? '<p className="font-display">Thank you for getting in touch.</p><p>We will get back with you soon. Have a great day!</p>'
            : '<p>Oops! Something went wrong. Please try again later.</p>';

        setNotification({
            ...notification,
            show: true,
            message: message,
            success: success,
        });

		setTimeout(() => {
			setNotification({...notification, show: false})
		}, 5000);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelect = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

	const handleCheckbox = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.checked
		});
	}

    const handleSubmit = (e) => {
        e.preventDefault();
		setSending(true);
        makeRequest();
    }

    const handleError = (err) => {
        if (err) {
            setError(err);
        }

        if (Object.hasOwn(err, 'validation_messages')) {
			const newErrors = {...err.validation_messages}
            setFieldErrors(newErrors);
        } else {
			handleNotification(false);
		}
    };

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/wp-json/gf/v2/forms/1/submissions`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic YXJjaGVyLWFkbWluOjd4MTcgUU9ZaCBCdUo5IFNBZk8gclBEUSAyVm9j',
        },
        data: JSON.stringify(formData),
    };

    async function makeRequest() {
        try {
            const response = await axios.request(config);
			setSending(false);
            setResponse(response.data);
            handleNotification(true);
        } catch (error) {
			setSending(false);
            handleError(error);
        }
    }

    return (
        <>
            <Button
                onClick={() => handleDrawer(true)}
                className={
                    'hidden lg:block fixed z-[100] -rotate-90 origin-bottom-right top-1/2 -translate-y-[300%] rounded-b-none' +
                    (open ? ' md:-right-12 ' : ' -right-12 md:right-0 ')
                }
                theme='accent'
            >
                Contact Us
            </Button>
            <ContactFormDrawer open={open} handleDrawer={handleDrawer}>
                <form
                    className='
			flex h-full flex-col divide-y divide-background-500 shadow-xl
			bg-foreground-100
			dark:bg-background-600'
                    action={`${apiUrl}/wp-json/gf/v2/forms/1/submissions`}
                    method='post'
                    onSubmit={handleSubmit}
                >
                    <div className='h-0 flex-1 overflow-y-auto'>
                        {/* Header */}
                        <div className='px-4 py-6 sm:px-6 bg-accent dark:bg-background-700'>
                            <div className='flex items-center justify-between'>
                                <Dialog.Title className='fluid-xl font-semibold leading-6 text-white'>
                                    Curious? We are, too!
                                </Dialog.Title>
                                <div className='ml-3 flex h-7 items-center'>
                                    <Button type='button' theme='icon' onClick={() => handleDrawer(false)}>
                                        <span className='sr-only'>Close panel</span>
                                        <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                    </Button>
                                </div>
                            </div>
                            <div className='mt-1'>
                                <p className='text-sm text-accent-100'>
                                    Tell us what’s on your mind, and we’ll make sure to connect you with the best team
                                    for the job.
                                </p>
                            </div>
                        </div>
                        {/* Body */}
                        <div className='flex flex-1 flex-col justify-between'>
                            <div className='divide-y divide-gray-200 px-4 sm:px-6'>
                                <div className='space-y-6 pb-5 pt-6'>
                                    <div>
                                        <ListBox
                                            label='My inquiry is related to:'
                                            name='input_1'
											id='input_1'
                                            value={formData.input_1}
                                            onChange={(event) => {
                                                handleSelect('input_1', event);
                                            }}
                                            items={['General', 'New Business', 'Press', 'Careers']}
											hasError={ Object.hasOwn(fieldErrors, '1') }
                                            error={ fieldErrors[1]}
                                        />
                                    </div>
                                    <div>
                                        <TextInput
                                            required
                                            label='Full name'
                                            placeholder='John Doe'
                                            name='input_3'
                                            value={formData.input_3}
                                            onChange={handleChange}
                                            hasError={  Object.hasOwn(fieldErrors, '3') }
                                            error={ fieldErrors[3]}
                                        />
                                    </div>
                                    <div>
                                        <TextInput
                                            label='Company'
                                            placeholder='Archer'
                                            name='input_4'
                                            value={formData.input_4}
                                            onChange={handleChange}
											hasError={  Object.hasOwn(fieldErrors, '4') }
                                            error={ fieldErrors[4]}
                                        />
                                    </div>
                                    <div>
                                        <TextInput
                                            required
                                            label='Email address'
                                            placeholder='johndoe@email.com'
                                            name='input_5'
                                            type='email'
                                            value={formData.input_5}
                                            onChange={handleChange}
                                            hasError={  Object.hasOwn(fieldErrors, '5') }
                                            error={ fieldErrors[5]}
                                        />
                                    </div>
                                    <div>
                                        <TextArea
                                            required
                                            label='Description'
                                            placeholder='How can we help?'
                                            name='input_6'
                                            value={formData.input_6}
                                            onChange={handleChange}
											hasError={  Object.hasOwn(fieldErrors, '6') }
                                            error={ fieldErrors[6]}
                                        />
                                    </div>
                                    {/* <div>
                                        <Checkbox
                                            label='Subscribe to our newsletter'
                                            name='input_7.1'
                                            value={formData.input_7}
                                            onChange={handleCheckbox}
											hasError={  Object.hasOwn(fieldErrors, '7') }
                                            error={ fieldErrors[7]}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Footer */}
                    <div className='flex flex-shrink-0 justify-end px-4 py-4 gap-2 bg-background-700'>
                        <Button type='button' theme='secondary' onClick={() => handleDrawer(false)}>
                            Cancel
                        </Button>
                        <button type='submit' className='btn btn-accent text-xs'>
                            Send {sending && <FontAwesomeIcon icon={faSpinner} spinPulse className='text-background w-4 h-4 ml-2' />}
                        </button>
                    </div>
                </form>
            </ContactFormDrawer>

            <ContactNotification
				data={notification}
            />
        </>
    );
}
