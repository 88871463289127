import React from 'react';
import SkeletonText from './SkeletonText';
import SkeletonImage from './SkeletonImage';

export default function SkeletonCard({ lines, direction = 'row' | 'column' }) {
    const flexDirection = direction === 'row' ? 'flex-row' : 'flex-col';
    return (
        <div className={`w-full flex flex-col gap-4 items-center ${flexDirection}`}>
            <SkeletonImage />
            <SkeletonText lines={lines} />
        </div>
    );
}
