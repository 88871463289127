/**
 * Block | Image Slider Block
 * 
 * @param
 * images
 * sliderOnDesktop
 * sliderOnMobile
 */

import React from 'react';
import { motion } from 'framer-motion';
import ContentSlider from '../../careers/components/ContentSlider';

export default function ImageSlider(props) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className='bg-foreground-100 pb-8 md:py-10'
        >
        <div
            className={`${props.sliderOnMobile ? 'block' : 'hidden'}
                        ${props.sliderOnDesktop ? ' lg:block lg:container' : ' lg:hidden [&>div>div]:justify-between'}`}
        >
            <ContentSlider slidesToShow={3.5}>
                {props.images.map((item, index) => (
                    <img
                        key={index}
                        src={item['url']}
                        alt={item['alt']}
                        className='max-h-[600] pr-4'
                    />
                ))}
            </ContentSlider>
        </div>
        <div
            className={`container ${props.sliderOnMobile ? 'hidden' : 'block'}
                                  ${props.sliderOnDesktop ? ' lg:hidden' : ' lg:block'}`}
        >
            <div
                className={`grid ${
                    props.images.length > 5 ? 'grid-flow-col' : ''
                } grid-cols-1 lg:grid-cols-${
                    props.images.length
                } gap-12 justify-items-center`}
            >
                {props.images.map((item, index) => (
                    <img key={index} src={item['url']} alt={item['alt']} />
                ))}
            </div>
        </div>
        </motion.section>
    )
}
