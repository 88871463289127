import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import Wrapper from '../../components/wrapper';
import PageLead from '../../components/PageLead';
import api from '../../utils/api';
import Seo from '/src/components/Seo';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion } from 'framer-motion';
import makeSeo from '../../utils/makeSeo';
import Blocks from '../../components/blocks/Blocks';

const bgStar = new URL('../../images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);

const defaultSeo = {
    title: 'Case Study Category | Archer',
    description: 'Our work inspires us to set our sights higher. Get inspired and see what we can do for you.',
};

export default function Categories(props) {
    const { slug } = useParams();
    const errorPage = '/';
    if (!slug) return <Navigate to={errorPage} />;

    const [loaded, setLoaded] = useState(false);
    const [content, setContent] = useState(null);
    const [seo, setSeo] = useState(defaultSeo);

    const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            intro: {
                pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
                featured_image: `${data?._embedded?.['wp:featuredmedia']?.[0]?.source_url}?as=webp&quality=65`,
            },
            blocks: data.acf.flexible_blocks,
        };
        setContent(dataContent);
    };

    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        api.getData(`pages?parent=6331&slug=${slug}&_embed&acf_format=standard`)
            .then((response) => {
                pageContent(response.data[0]);
                setLoaded(true);
                getSeo(response.data[0].yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

    let countBlocks = 0;
    return (
        <>
            <Seo title={seo.title} description={seo.description} />
            {!loaded ? (
                <div className='h-screen'></div>
            ) : (
                <Wrapper theme='light' openContactForm={props.openContactForm}>
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0, delay: 0 }}
                    >
                        {content && (
                            <motion.section
                                initial={{ opacity: 0, y: 40 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.2 }}
                                layout
                                className='relative transparent pt-44'
                            >
                                <PageLead
                                    title={content.title}
                                    titleVisible={content.intro.showTitle}
                                    subtitle={content.intro.headline}
                                    text={content.intro.content}
                                    image={content.intro.featured_image}
                                    style='featured-full-bot'
                                    minHeight={400}
                                />
                            </motion.section>
                        )}

                        {/* accent star */}
                        <div style={{ '--image-url': `url(${bgStar})` }}
                            className='bg-white 
                                relative overflow-hidden
                                md:before:block 
                                before:absolute 
                                before:bg-[image:var(--image-url)] 
                                before:bg-contain 
                                before:bg-no-repeat 
                                before:inset-x-1/2 
                                before:-translate-y-1/2 
                                before:-translate-x-1/2 
                                before:top-0 
                                before:opacity-100 
                                before:w-[425] 
                                before:h-[425]'
                        >
                            {content && (content.blocks.map((block, index) => {
                                countBlocks++;
                                return(
                                    <Blocks
                                        block={block}
                                        key={index}
                                        accent={`${countBlocks <= 1 ? 'star' : ''}`}
                                    />
                                )
                            }))}
                        </div>
                    </motion.div>
                </Wrapper>
            )}
        </>
    );
}
