import React, { useEffect, useState } from 'react';
import Wrapper from '/src/components/wrapper';
import PageLead from '/src/components/PageLead';
import BlogPageContent from './components/BlogPageContent';
import Seo from '/src/components/Seo';
import api from '/src/utils/api';
import { motion } from 'framer-motion';
import makeSeo from '../../utils/makeSeo';

const defaultSeo = {
    title: 'The Latest from Archer | Archer',
    description:
        'Here, you’ll find pieces by and about Archer, an employee-owned agency. Find thought leadership articles, industry accolades and more.',
};

export default function Latest(props) {
    const [content, setContent] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [seo, setSeo] = useState(defaultSeo);

    const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            intro: {
                pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
            },
        };
        setContent(dataContent);
    };

    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    useEffect(() => {
        api.getData('pages/37?acf_format=standard')
            .then((response) => {
                pageContent(response.data);
                setLoaded(true);
                getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
        // instantly scroll to top of page
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <>
            <Seo title={seo.title} description={seo.description} />
            {!loaded ? (
                <>
                    <div className='h-screen'></div>
                </>
            ) : (
                <Wrapper theme='light' openContactForm={props.openContactForm}>
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0, delay: 0 }}
                    >
                        {content ? (
                            <motion.section
                                initial={{ opacity: 0, y: 40 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.2 }}
                                layout
                                className='relative transparent pt-44'
                            >
                                <PageLead
                                    title={content.title}
                                    titleVisible={content.intro.showTitle}
                                    subtitle={content.intro.headline}
                                    text={content.intro.content}
                                />
                            </motion.section>
                        ) : (
                            <div className='h-screen bg-foreground-100'></div>
                        )}
                        {content ? (
                            <motion.section
                                initial={{ opacity: 0, y: 40, rotate: 2 }}
                                animate={{ opacity: 1, y: 0, rotate: 0 }}
                                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                                layout
                                className='bg-foreground-100 min-h-1/2screen'
                            >
                                <BlogPageContent />
                            </motion.section>
                        ) : (
                            <div className='min-h-screen'></div>
                        )}
                    </motion.div>
                </Wrapper>
            )}
        </>
    );
}
