import axios from 'axios';

// Create instance called instance
const instance = axios.create({
    baseURL: 'https://api.resumatorapi.com/v1/jobs/status/open',
    headers: {
        'content-type': 'application/json',
    },
});

export default {
    getData: () =>
        instance({
            method: 'GET',
            url: `?apikey=${process.env.REACT_APP_JAZZHR_API}`,
        }),
};
