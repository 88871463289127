import { useState } from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import axios from 'axios';
import { getAPI } from '/src/utils/api';
import ContactNotificationBlock from './ContactNotificationBlock';
import ListBox from '/src/components/forms/listbox';
import TextInput from '/src/components/forms/textInput';
import TextArea from '/src/components/forms/textArea';
import Checkbox from '/src/components/forms/checkbox';
import Button from '/src/components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function ContactPageForm(props) {
	const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
	const [sending, setSending] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', success: null });
    const [fieldErrors, setFieldErrors] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(false);

	const apiUrl = getAPI();

    const options = ['General', 'New Business', 'Press', 'Careers'];

	const inputDefaults = {
        input_1: options[0],
        input_3: '',
        input_4: '',
        input_5: '',
        input_6: '',
        'input_7.1': false,
    }

    const [formData, setFormData] = useState(inputDefaults);

    const handleNotification = (success) => {
        let message = success
            ? '<p className="font-display">Thank you for getting in touch.</p><p className="text-slate-900">We will get back with you soon. Have a great day!</p>'
            : '<p>Oops! Something went wrong. Please try again later.</p>';

        setNotification({
            ...notification,
            show: true,
            message: message,
            success: success,
        });

        if (success) {
            setButtonDisabled(true);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelect = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

	const handleCheckbox = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.checked
		});
	}

    const handleSubmit = (e) => {
        e.preventDefault();
		setSending(true);
        makeRequest();
    }

    const handleError = (err) => {
        if (err) {
            setError(err);
        }

        if (Object.hasOwn(err, 'validation_messages')) {
			const newErrors = {...err.validation_messages}
            setFieldErrors(newErrors);
        } else {
			handleNotification(false);
		}
    };

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${apiUrl}/wp-json/gf/v2/forms/1/submissions`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic YXJjaGVyLWFkbWluOjd4MTcgUU9ZaCBCdUo5IFNBZk8gclBEUSAyVm9j',
        },
        data: JSON.stringify(formData),
    };

    async function makeRequest() {
        try {
            const response = await axios.request(config);
			setSending(false);
            setResponse(response.data);
            handleNotification(true);
        } catch (error) {
            // console.error('Error response data:', error.response.data);
			setSending(false);
            handleError(error.response.data);
        }
    }

    return (
        <>
            <form
                className='flex h-full flex-col divide-y'
                action={`${apiUrl}/wp-json/gf/v2/forms/1/submissions`}
                method='post'
                onSubmit={handleSubmit}
            >
                <div className='h-0 flex-1 overflow-y-auto'>
                    {/* Header */}
                    <div className='px-4 lg:py-6 sm:px-6'>
                        <div className='flex items-center justify-between'>
                            <h3 className='text-4xl font-serif text-accent font-normal mb-4 lg:text-4xl'>
                                {props.formHeading}
                            </h3>
                        </div>
                        <div className='text-slate-900 text-lg mt-1 max-w-lg'>
                            {parse(props.formContent)}
                        </div>
                    </div>
                    {/* Body */}
                    <div className='flex flex-1 flex-col justify-between'>
                        <div className='divide-y divide-gray-200 px-4 sm:px-6'>
                            <div className='space-y-6 pb-5 pt-6'>
                                <div className='[&>div]:lg:flex [&>div]:items-center [&>div]:xl:gap-6
                                                [&>div>label]:flex-shrink-0 [&>div>label]:lg:w-1/2 [&>div>label]:text-slate-900 [&>div>label]:text-lg'>
                                    <ListBox
                                        label='My inquiry is related to:'
                                        name='input_1'
                                        id='input_1'
                                        value={formData.input_1}
                                        onChange={(event) => {
                                            handleSelect('input_1', event);
                                        }}
                                        items={['General', 'New Business', 'Press', 'Careers']}
                                        hasError={ Object.hasOwn(fieldErrors, '1') }
                                        error={ fieldErrors[1] }
                                    />
                                </div>
                                <hr className='hidden lg:block border-1 border-foreground-300' />
                                <div className='[&>div>label]:text-slate-900 [&>div>label]:text-lg
                                                [&>div>div>input]:placeholder-background-300'>
                                    <TextInput
                                        required
                                        label='Full Name'
                                        placeholder='Jane Doe'
                                        name='input_3'
                                        value={formData.input_3}
                                        onChange={handleChange}
                                        hasError={ Object.hasOwn(fieldErrors, '3') }
                                        error={ fieldErrors[3] }
                                    />
                                </div>
                                <div className='flex flex-col lg:flex-row lg:justify-between gap-6'>
                                    <div className='w-full [&>div>label]:text-slate-900 [&>div>label]:text-lg
                                                    [&>div>div>input]:placeholder-background-300'>
                                        <TextInput
                                            label='Company'
                                            placeholder='Archer'
                                            name='input_4'
                                            value={formData.input_4}
                                            onChange={handleChange}
                                            hasError={ Object.hasOwn(fieldErrors, '4') }
                                            error={ fieldErrors[4] }
                                        />
                                    </div>
                                    <div className='w-full [&>div>label]:text-slate-900 [&>div>label]:text-lg
                                                    [&>div>div>input]:placeholder-background-300'>
                                        <TextInput
                                            required
                                            label='Email Address'
                                            placeholder='email@address.com'
                                            name='input_5'
                                            type='email'
                                            value={formData.input_5}
                                            onChange={handleChange}
                                            hasError={ Object.hasOwn(fieldErrors, '5') }
                                            error={ fieldErrors[5] }
                                        />
                                    </div>
                                </div>
                                <div className='[&>div>div>textarea]:lg:h-56
                                            [&>div>label]:text-slate-900 [&>div>label]:text-lg
                                            [&>div>div>textarea]:placeholder-background-300'>
                                    <TextArea
                                        required
                                        label='Message'
                                        placeholder=''
                                        name='input_6'
                                        value={formData.input_6}
                                        onChange={handleChange}
                                        hasError={ Object.hasOwn(fieldErrors, '6') }
                                        error={ fieldErrors[6] }
                                    />
                                </div>
                                {/* <div className='[&>div>label]:text-slate-900 [&>div>label]:text-lg'>
                                    <Checkbox
                                        label='Subscribe to our newsletter'
                                        name='input_7.1'
                                        value={formData.input_7}
                                        onChange={handleCheckbox}
                                        hasError={ Object.hasOwn(fieldErrors, '7') }
                                        error={ fieldErrors[7] }
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer */}
                <div className='flex flex-shrink-0 justify-start px-4 sm:px-6 gap-2'>
                    <button type='submit' disabled={buttonDisabled} className='btn btn-accent text-xs pt-3 pb-2 font-semibold disabled:opacity-25 disabled:pointer-events-none'>
                        Send {sending && <FontAwesomeIcon icon={faSpinner} spinPulse className='text-background w-4 h-4 ml-2' />}
                    </button>
                </div>
            </form>

            <ContactNotificationBlock
				data={notification}
            />
        </>
    );
}
