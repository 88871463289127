import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import parse from 'html-react-parser/dist/html-react-parser';

export default function ContactNotificationBlock({ data }) {
    const { message, success, show } = data;
    return (
        <div
            aria-live='assertive'
            className='flex px-4 py-6 sm:p-6 text-pretty'
			id='notification'
        >
            <div className='flex w-full flex-col'>
                {/* Notification text, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                    show={show}
                    as={Fragment}
                    enter='transform ease-out duration-300 transition'
                    enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                    enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='text-lg text-accent-500'>{parse(message)}</div>
                </Transition>
            </div>
        </div>
    );
}
