/**
 * Block | Bordered Callout
 * Renamed from: Text + Image Block
 *
 * @param
 * content
 * WYSIWYG content
 *
 * image
 * Image array
 */

import React from 'react';
import { motion } from 'framer-motion';

const bgStar = new URL('../../images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);

export default function BorderedCallout({ content, image }) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className='block__textimage
            relative bg-foreground-100 py-12 md:py-16'
        >
            <div className='relative z-10 max-w-7xl container border-y-2 border-foreground py-16 text-black'>
                <div className='md:flex items-center justify-center gap-x-10 lg:gap-x-32 text-center md:text-left'>
                    {image && (
                        <img src={image.url}
                            alt={image.alt}
                            className='md:order-2 max-w-[200px] mx-auto md:mx-0'
                        />
                    )}
                    {content && (
                        <div
                            className='md:order-1 prose prose-lg not-prose pt-6 [&>p]:max-w-xl [&>p]:mx-auto'
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    )}
                </div>
            </div>
            <img src={bgStar}
                 alt=''
                 className={`absolute
                    origin-top
                    w-[475px]
                    h-[475px]
                    -left-[240px]
                    -top-[5%]
                    select-none
                    z-0
                    hidden
                    md:block
                `}
                 draggable='false' />
        </motion.section>
    )
}
