
export default function Button(props) {
  let theme = props.theme ?? 'primary'
  if (theme === 'primary') {
    theme = `
      text-background-500
      bg-foreground-500
      hover:bg-foreground-200
      active:hover:bg-foreground-100
      active:hover:ring-foreground
      active:hover:ring-offset-background-500`
  } else if (theme === 'secondary') {
    theme = `
      text-background
      dark:text-foreground
      bg-background-100
      dark:bg-background-500
      hover:bg-background-100
      dark:hover:bg-background-600
      active:hover:bg-background-200
      dark:active:hover:bg-background-500
      active:hover:ring-background-500
      active:hover:ring-offset-background-700
      `
  } else if (theme === 'accent') {
    theme = `
      text-background-900
      bg-accent
      hover:bg-accent-600
      active:hover:bg-accent
      active:hover:ring-accent
      active:hover:ring-offset-background`
  } else if (theme === 'icon') {
    theme = `
      text-foreground
      bg-transparent
      hover:bg-background-400/50
      active:hover:bg-background-400/25
      active:hover:ring-accent-500
      active:hover:ring-offset-background
      p-1.5
      rounded-md`
  }

  return(
    <a
      href={props.url}
      onClick={props.onClick}
      onKeyDown={(e) => {if (e.key === ' ' || e.key === 'Enter') props.onClick()}}
      tabIndex={0}
      className={`
        outline-none
        text-xs
        no-underline
        rounded-md
        cursor-pointer
        duration-200
        active:hover:ring-2
        active:hover:ring-offset-2
        focus:ring-2
        focus:ring-offset-2
        focus:ring-foreground
        dark:focus:ring-background-400
        dark:focus:ring-offset-background
        focus:ring-offset-background
        inline-flex items-center
      `
        + (props.theme === 'icon' ? ' p-1.5 ' : (props.longText ? ' px-4 pt-1.5 pb-2 ' : ' px-4 pt-2 pb-1.5 ' ))
        + (props.longText ? ' normal-case tracking-normal font-sans ' : ' font-display uppercase tracking-widest ')
        + props.className + ' ' + theme
      }>
        {props.children}
      </a>
  )
}