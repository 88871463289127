import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import HeadshotPlaceholder from '/src/images/headshots/placeholder_woman.png';
// import Social from '/src/components/Social';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function ContactCard({person}) {
	const {id, name, title, email, social, image, heading} = person;
	const getImage = (url) => url ? `${url}&width=300` : HeadshotPlaceholder
    return (
        <div id={id} className='flex flex-col items-center text-center'>
            <a href={social} target='_blank' title='LinkedIn'>
                <figure className='relative group border-4 border-foreground hover:border-accent transition-all duration-300 
                                    rounded-full w-44 md:w-64 max-w-[215px] aspect-square overflow-hidden mb-4 bg-white'>
                    <img
                        src={getImage(image.default)}
                        alt={`${name}'s headshot`}
                        className='absolute w-full h-auto rounded-full'
                    />
                </figure>
            </a>
            <h3 className='text-xl lg:text-2xl text-accent-500 mb-2'>{heading}</h3>
            <p className='text-lg text-background w-4/5 mb-1'>{name}</p>
            <p className='text-lg text-background w-4/5 mb-3'
                dangerouslySetInnerHTML={{ __html: title }} />
            <div className='flex items-center justify-center gap-x-3'>
                <div className='group'>
                    {/* {social && (
                        <Social
                            theme='gold'
                            selected-socials={[
                                {
                                    name: 'Email',
                                    url: social,
                                    icon: faEnvelope,
                                    text: `Reach out to ${name}`,
                                },
                            ]}
                        />
                    )} */}
                    <a
                        className='text-slate-900 group-hover:text-accent transition-colors'
                        aria-label={`Reach out to ${name}`}
                        href={`mailto:${email}`}
                        title='Email'
                    >
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className={`w-8 h-8 flex-shrink-0`}
                        />
                        <span className='sr-only'>{`Reach out to ${name}`}</span>
                    </a>
                </div>
                {name && name.trim() === 'Marc Girard' && (
                    <a href='/careers/' className='text-sm text-accent-500 underline -mt-1'>Open Roles</a>
                )}
            </div>
        </div>
    );
}
