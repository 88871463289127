import React from 'react';
import { useEffect, useState } from 'react';
import Stars from '../images/stars.png?as=webp&quality=45&width=2000';
import Header from './Header';
import Footer from './Footer';
import Button from './button';
import HeaderHome from './HeaderHome';

export default function Wrapper(props) {
    const [sectionHeight, setSectionHeight] = useState('0px');

    let headerHeight = 0;
    // Get height measurement from header#site-header
    useEffect(() => {
        // check to see if #site-header exists in dom
        if (document.getElementById('site-header') !== null)
            headerHeight = document.getElementById('site-header').offsetHeight;
        // look for resizing
        window.addEventListener('resize', () => setSectionHeight(headerHeight));
        setSectionHeight(headerHeight);
    }, []);
    useEffect(() => {
        (function () {
            var parallaxDistant = document.querySelectorAll('.distant-stars'),
                speedDistant = -0.1;
            var parallaxNear = document.querySelectorAll('.near-stars'),
                speedNear = -0.2;
            window.onscroll = function () {
                [].slice.call(parallaxDistant).forEach(function (el, i) {
                    var windowYOffset = window.pageYOffset,
                        elBackgrounPos = '50% ' + windowYOffset * speedDistant + 'px';
                    el.style.backgroundPosition = elBackgrounPos;
                });
                [].slice.call(parallaxNear).forEach(function (el, i) {
                    var windowYOffset = window.pageYOffset,
                        elBackgrounPos = '50% ' + windowYOffset * speedNear + 'px';
                    el.style.backgroundPosition = elBackgrounPos;
                });
            };
        })();
    }, []);
    var showUIClasses = 'relative z-50';

    return (
        <>

            <div className='relative z-50 w-full overflow-hidden'>
                <div className="">
                {props.homepage ? (
                    <>
                        <HeaderHome animated={false} />
                        <Header homepage={true} openContactForm={props.openContactForm} />
                    </>
                ) : (
                    <Header openContactForm={props.openContactForm} />
                )}
                </div>
                <main
                    className='relative text-foreground-500 text-lg overflow-visible'
                    //style={props.homepage ? null : { paddingTop: `${sectionHeight}px` }}
                    data-theme={props.theme}
                >
                    {props.children}
                </main>
                {props.homepage ? null : <Footer homepage={props.homepage} theme={props.theme} />}
            </div>
        </>
    );
}
