import React, { useState, useEffect } from 'react';
import api from '/src/utils/api';
import ContactCard from './ContactCard';
import ContactCardMobile from './ContactCardMobile';
import { motion } from 'framer-motion';
import viewportGet from '/src/utils/viewportGet';

export default function ContactssSection({ contacts }) {
    const [contactsArray, setContactsArray] = useState(null);
    const isDesktop = viewportGet('x') > 1023;

    const personIds = contacts.map(contact => contact.contact_person).join(',');

    useEffect(() => {
        api.getData(`authors/?include=${personIds}&orderby=include&acf_format=standard&per_page=3`)
            .then((response) => {
                // console.log(response.data);
                setContactsArray(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [contacts]);

    const makeContact = (item, contacts) => {
        return {
            id: item.id,
            name: item.title.rendered,
            title: item.acf.author_job_title,
            email: item.acf.email_address,
            social: item.acf.author_social[0].asm_link,
            image: {
                default: item.acf.headshot.url,
            },
            heading: contacts.contact_heading,
        };
    };
    return (
        contactsArray && (
            <motion.section
                initial={{ opacity: 0, y: 40, rotate: 2 }}
                animate={{ opacity: 1, y: 0, rotate: 0 }}
                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                layout
                className={`bg-gray-200 lg:bg-white`}
            >
                <div className='container overflow-visible'>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 xl:gap-14 lg:gap-y-20 not-prose text-pretty max-w-[1200px] mx-auto px-2'>
                        {contactsArray.map((item, index) => {
                            const person = makeContact(item, contacts[index]);
        
                            return isDesktop ? (
                                <ContactCard person={person} key={index} />
                            ) : (
                                <ContactCardMobile person={person} key={index} />
                            );
                        })}
                    </div>
                </div>
            </motion.section>
        )
    );
}
