/**
 * Block | Video Block
 *
 * @param
 * video_embed
 * url containing the video from YouTube or Vimeo
 *
 * video_placeholder
 * image to show above the video or iframe
 */

import React from 'react';
import { motion } from 'framer-motion';
import Starline from '../../routes/case-studies/components/Starline';
import Video from '../../routes/case-studies/components/Video';

export default function Video({ video_embed, video_placeholder }) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className={`bg-foreground-100 py-10 lg:py-16 pb-16 lg:pb-20 relative overflow-hidden`}
        >
            <div className='container text-black relative z-10'>
                <div className='max-w-4xl mx-auto'>
                    {video_embed && (
                        <Video
                            videoSource={video_embed}
                            placeholderImage={video_placeholder}
                        />
                    )}
                </div>
            </div>
            <Starline opacity='60' sharp />
        </motion.section>
    )
}
