import { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import parse from 'html-react-parser/dist/html-react-parser';

export default function ContactNotification({ data }) {
    const { message, success, show } = data;
    return (
        <div
            aria-live='assertive'
            className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50'
			id='notification'
        >
            <div className='flex w-full flex-col items-center space-y-4 sm:items-end dark'>
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                    show={show}
                    as={Fragment}
                    enter='transform ease-out duration-300 transition'
                    enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                    enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div
                        className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-foreground-100
                    dark:bg-background-600 border border-background-400'
                    >
                        <div className='p-4'>
                            <div className='flex items-center'>
                                <div className='flex-shrink-0'>
                                    <FontAwesomeIcon
                                        className='text-accent w-8 h-8'
                                        icon={success ? faCircleCheck : faCircleXmark}
                                    />
                                </div>
                                <div className='ml-3 w-0 flex-1 pt-0.5 text-lg dark:text-white'>{parse(message)}</div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}
