/**
 * Block | Fullwidth Image Block
 *
 * @param
 * image
 * Main image that by default will display at all times
 *
 * imageMobile
 * Main image that will take the place of the image on mobile.
 *
 * fullBleed
 * True/False as to whether image breaks the conttainer.
 */

import React from 'react';
import { motion } from 'framer-motion';

export default function FullWidthImage(props) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className={`bg-foreground-100 py-8 md:py-12`}
        >
            <div className={`${!props.fullBleed && 'container xl:max-w-7xl mx-auto'}`}>
                {props.image && (
                    <img
                        src={props.image.url}
                        alt={props.image['alt']}
                        className={`${props.imageMobile ? 'hidden md:block' : 'block'} 
                        ${props.fullBleed && 'w-full'} 
                        mx-auto select-none`}
                        draggable='false'
                    />
                )}
                {props.imageMobile && (
                    <img
                        src={props.imageMobile.url}
                        alt={props.imageMobile['alt']}
                        className='block md:hidden w-full mx-auto select-none'
                        draggable='false'
                    />
                )}
            </div>
        </motion.section>
    );
}
