import React, { useState, useEffect } from 'react';
import Wrapper from '/src/components/wrapper';
import api from '/src/utils/api';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion } from 'framer-motion';
import Seo from '/src/components/Seo';
import makeSeo from '../../utils/makeSeo';
import ContactsSection from './components/ContactsSection';
import ContactPageForm from './components/ContactPageForm';

const archerOffice = new URL('/src/images/archer-building.png?as=webp&quality=45&width=600', import.meta.url);
import Starline from './components/Starline';

const defaultSeo = {
    title: 'Contact Us | Archer',
    description: 'We want to hear from you.',
};


export default function Contact(props) {
    const [loaded, setLoaded] = useState(false);
    const [heroLoaded, setHeroLoaded] = useState(false);
    const [content, setContent] = useState(null);
    const [contacts, setContacts] = useState(null);
	const [seo, setSeo] = useState(defaultSeo);

    const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            intro: {
                pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
            },
            contactsList: {
                contactHeading: data.acf.contact_heading,
                contactPerson: data.acf.contact_person,
            },
            mapHeading: data.acf.map_heading,
            mapContent: data.acf.map_body_content,
            downloadHeading: data.acf.download_heading,
            downloadImage: data.acf.download_image,
            downloadLink: data.acf.download_link,
            formHeading: data.acf.form_heading,
            formContent: data.acf.form_content,
        };
        setContent(dataContent);
    };

	const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
        api.getData('pages/6811?acf_format=standard')
            .then((response) => {
                // console.log(response.data);
                pageContent(response.data);
                setContacts(response.data.acf.contact_people);
                setLoaded(true);
				getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

    // Let's try to remove the extra contact form from here for now.
    useEffect(() => {
        const tabContactButton = document.querySelector('#contact-form-tab');
        const tabContactForm = document.querySelector('#headlessui-dialog-panel-\\:rb\\:');
        if (tabContactForm) {
        //   console.log('removing form');
          tabContactForm.parentNode.removeChild(tabContactForm);
        }
        if (tabContactButton) {
        //   console.log('removing tab');
          tabContactButton.parentNode.removeChild(tabContactButton);
        }
    }, []);

    const loadedClass = 'opacity-100 translate-y-0';
    const unloadedClass = 'opacity-0 translate-y-8';
    useEffect(() => {
        const timer = setTimeout(() => {
            setHeroLoaded(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <Seo title={seo.title} description={seo.description} />

            <Wrapper theme='gray'>
                {!loaded ? (
                    <div className='h-screen'></div>
                ) : (
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0, delay: 0 }}
                    >
                        <motion.section
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.2 }}
                            layout
                            className='relative transparent pt-12 lg:pt-44 pb-8 lg:pb-20'
                        >
                            {content && (
                                <div className='relative container pt-16 z-10'>
                                    <div className='grid md:grid-cols-2 gap-y-14 md:gap-8 items-start'>
                                        <div className={`duration-300 ease-in ${heroLoaded ? loadedClass : unloadedClass}`}>
                                            <div className='text-xl text-foreground leading-8 lg:leading-10'>
                                                <h1 className='text-5xl font-serif text-accent font-normal mb-5 lg:text-6xl xl:text-7xl xl:leading-[5rem] max-w-[540]'>
                                                    {content.intro.headline}
                                                </h1>
                                                {parse(content.intro.content)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <img
                                src={archerOffice}
                                alt=''
                                className='absolute left-1/2 -bottom-1/4 sm:-bottom-1/3 -translate-x-1/2 max-w-none z-0 select-none sm:max-w-full'
                                draggable='false'
                            />
                        </motion.section>

                        <motion.section
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.2 }}
                            layout
                            className='relative bg-gray-200 lg:bg-white pt-10 lg:pt-24 pb-0'
                        >
                            <div className={`duration-300 ease-in ${heroLoaded ? loadedClass : unloadedClass}`}>
                                {contacts && <ContactsSection contacts={contacts} />}
                            </div>
                        </motion.section>

                        <motion.section
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.2 }}
                            layout
                            className='relative bg-gray-200 lg:bg-white pt-10 lg:pt-24 pb-10 lg:pb-24'
                        >
                            {content && (
                                <div className='relative container z-10'>
                                    <div className='grid lg:grid-cols-2 gap-y-14 md:gap-8 items-start px-4'>
                                        <div className={`order-2 content-center h-full duration-300 ease-in ${heroLoaded ? loadedClass : unloadedClass}`}>
                                            <div className='text-xl text-foreground leading-8 lg:leading-10'>
                                                <h2 className='text-4xl font-serif text-accent font-normal lg:text-4xl xl:text-4xl mb-2'>
                                                    {content.mapHeading}
                                                </h2>
                                                <div className='prose text-pretty [&>p]:mt-0 [&>p]:mb-4 [&>p]:text-xl lg:[&>p]:leading-8 [&>p>a]:text-accent'>
                                                    {parse(content.mapContent)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='order-1 overflow-hidden w-full h-full'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d44880.23811551463!2d-90.07556800675707!3d35.16540755871802!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d57e95df8977f5%3A0x5f90ff598445c54b!2sArcher!5e0!3m2!1sen!2sus!4v1721217952841!5m2!1sen!2sus" className='w-full max-w-[330px] md:max-w-[450px] lg:max-w-full h-[345px] max-h-[250px] md:max-h-[300px] lg:max-h-full' width="660" height="345" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </motion.section>

                        <motion.section
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.2 }}
                            layout
                            className='relative bg-gray-200 pt-8 lg:pt-24 pb-28 lg:pb-44'
                        >
                            {content && (
                                <div className='relative container z-10'>
                                    <div className='grid lg:grid-cols-2 md:gap-8 items-start'>

                                        <div className='lg:order-2 mt-0 mb-16 lg:mt-16 lg:mb-0 mx-auto w-full'>
                                            <div className='relative bg-white lg:pt-4 pb-3 sm:pb-0 lg:pb-8 pl-6 lg:pl-6 pr-3.5 lg:pr-6 text-left lg:text-center mx-auto w-full lg:max-w-[400px] rounded-md shadow-md'>

                                                <div className='flex gap-6 lg:block'>
                                                    {content.downloadImage && (
                                                        <div className='relative -top-5 sm:-top-8 lg:-top-16 -mb-16'>
                                                            <img
                                                                src={content.downloadImage['url']}
                                                                alt={content.downloadImage['alt']}
                                                                className='mx-auto w-full max-w-[100px] sm:max-w-[140px]'
                                                            />
                                                        </div>
                                                    )}
                                                    <div className='flex flex-col lg:block text-pretty'>
                                                        <h3 className='text-2xl sm:text-3xl font-serif text-accent font-normal pt-4 mb-0.5 lg:mb-2 lg:text-3xl'>
                                                            {content.downloadHeading}
                                                        </h3>
                                                        <a href={parse(content.downloadLink['url'])} target='_blank' className='group flex gap-2 justify-center'>
                                                            {/* Download Icon for desktop */}
                                                            <svg className="hidden lg:inline" width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 7.60583H3.5C2.90326 7.60583 2.33097 7.84289 1.90901 8.26484C1.48705 8.6868 1.25 9.2591 1.25 9.85583V18.8558C1.25 19.4526 1.48705 20.0249 1.90901 20.4468C2.33097 20.8688 2.90326 21.1058 3.5 21.1058H12.5C13.0967 21.1058 13.669 20.8688 14.091 20.4468C14.5129 20.0249 14.75 19.4526 14.75 18.8558V9.85583C14.75 9.2591 14.5129 8.6868 14.091 8.26484C13.669 7.84289 13.0967 7.60583 12.5 7.60583H11M5 11.3558L8 14.3558M8 14.3558L11 11.3558M8 14.3558V1.60583" className='stroke-black group-hover:stroke-accent transition-colors' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            {/* File Icon for mobile */}
                                                            <svg className="inline lg:hidden" width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16.5 13.25V10.625C16.5 9.72989 16.1444 8.87145 15.5115 8.23851C14.8786 7.60558 14.0201 7.25 13.125 7.25H11.625C11.3266 7.25 11.0405 7.13147 10.8295 6.9205C10.6185 6.70952 10.5 6.42337 10.5 6.125V4.625C10.5 3.72989 10.1444 2.87145 9.51149 2.23851C8.87855 1.60558 8.02011 1.25 7.125 1.25H5.25M7.5 1.25H2.625C2.004 1.25 1.5 1.754 1.5 2.375V19.625C1.5 20.246 2.004 20.75 2.625 20.75H15.375C15.996 20.75 16.5 20.246 16.5 19.625V10.25C16.5 7.86305 15.5518 5.57387 13.864 3.88604C12.1761 2.19821 9.88695 1.25 7.5 1.25Z" className='stroke-black group-hover:stroke-accent transition-colors' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <span className='text-sm sm:text-lg text-slate-900 group-hover:text-accent transition-colors underline sm:-mt-0.5'>
                                                                {parse(content.downloadLink['title'])}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className={`lg:order-1 duration-300 ease-in ${heroLoaded ? loadedClass : unloadedClass}`}>
                                            <ContactPageForm formHeading={content.formHeading} formContent={content.formContent} />
                                        </div>

                                    </div>
                                </div>
                            )}

                            <Starline opacity='100' sharp />
                        </motion.section>

                    </motion.div>
                )}
            </Wrapper>
        </>
    );
}
