import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from "react-dom/client";
import { ParallaxProvider } from "react-scroll-parallax";
import { DevTools } from '/src/components/devTools';
import { motion, useScroll, useTransform } from 'framer-motion';

import Stars from './images/stars.png?as=webp&quality=45&width=2000'
import Marquee from 'react-fast-marquee';
import SpaceCloud from './images/space-cloud-2.png?as=webp&quality=75';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./index.css";

import Home from "./routes/index";
import NewIndex from './routes/index';
import People from "./routes/people";
import Contact from "./routes/contact";
import Careers from "./routes/careers";
import Latest from "./routes/latest";
import Post from "./routes/post";
import Work from "./routes/work";
import Categories from './routes/categories';
import CaseStudy from "./routes/case-studies";
import ContactForm from './components/ContactForm';
import PrivacyPolicy from './routes/privacy-policy';
import Error404 from './routes/error404';
import viewportGet from './utils/viewportGet';
import DevTools from './components/devTools';
import LanderPdfDownload from './routes/landers/pdf-download';
import Sitemap from "./routes/sitemap";

export function ParentWrapper(props) {
  // change background color while scrolling
  const { scrollYProgress } = useScroll();
  const backgroundColor = useTransform(
    scrollYProgress,
    [0, 0.5, 1],
    ["#13162A", "#1C1D3D", "#272E38"]
  )

  const [contactOpen, setContactOpen] = React.useState(false);
  const openContactForm = () => setContactOpen(!contactOpen);
  const router = createBrowserRouter([
    {
      element: <Home viewportY={viewportGet('y')} viewportX={viewportGet('x')} openContactForm={openContactForm} />, path: "/",
    },{
      element: <Contact openContactForm={openContactForm} />, path: "/contact/",
    },{
      element: <Careers openContactForm={openContactForm} />, path: "/careers/",
    },{
      element: <People openContactForm={openContactForm} />, path: "/people/",
    },{
      element: <Latest openContactForm={openContactForm} />, path: "/latest/",
    },{
      element: <Work openContactForm={openContactForm} />, path: "/work/",
    },{
      element: <Post openContactForm={openContactForm} />, path: "/latest/:slug/",
    },{
      element: <Home openContactForm={openContactForm} />, path: "/case-studies/",
    },{
      element: <PrivacyPolicy openContactForm={openContactForm} />, path: "/privacy-policy/",
    },{
      element: <NewIndex openContactForm={openContactForm} />, path: "/index/",
    },{
      element: <CaseStudy openContactForm={openContactForm} />, path: "/case-studies/:term/:slug/",
    },{
      element: <Categories openContactForm={openContactForm} />, path: "/case-studies/:slug/",
    },{
      element: <LanderPdfDownload openContactForm={openContactForm} />, path: "/personalservicesresearch/",
    },{
      element: <Error404 openContactForm={openContactForm} />, path: "*",
    },{
      element: <Sitemap />, path: "/sitemap/",
    },
  ]);
  return (
    <>
    { process.env.NODE_ENV !== 'production' ?
        <DevTools viewportY={viewportGet('y')} viewportX={viewportGet('x')} />
        : null
    }
      <div className="relative z-50">
        <div className='relative z-0'>
          <RouterProvider router={router} />
        </div>
      </div>
      <div className="relative z-10">
        <div className='w-full overflow-hidden h-auto fixed top-0 left-0 right-0 z-10 scale-[2] '>
          <Marquee gradient={false} speed={30}>
            <img src={SpaceCloud} className='w-[100%] max-w-[100%]' />
          </Marquee>
        </div>
        <div className="fixed inset-0 bg-repeat distant-stars opacity-30" style={{ backgroundImage: `url(${Stars})`, backgroundSize: '800px auto' }}></div>
        <div className="fixed inset-0 bg-repeat near-stars opacity-20" style={{ backgroundImage: `url(${Stars})`, backgroundSize: '2000px auto' }}></div>
      </div>
      <motion.div className="z-0 fixed inset-0 " style={{ backgroundColor }}></motion.div>

      <div id="contact-form-tab">
        <ContactForm open={contactOpen} setOpen={openContactForm} />
      </div>
    </>
  )
}
ReactDOM.createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <ParallaxProvider>
      <ParentWrapper />
    </ParallaxProvider>
   </React.StrictMode>
);
