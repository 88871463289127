/**
 * Block | Quotes Block
 *
 * @param
 * carousel_slides
 * Repeater for each slide to show. Slides contain the following fields:
 *  - logo ( image url )
 *  - content ( text area )
 *  - client_name ( text )
 *  - client_role ( text )
 */

import React from 'react';
import { motion } from 'framer-motion';
import ContentSlider from '../../routes/careers/components/ContentSlider';

export default function Quotes({ carousel_slides }) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className='block__quotes
            bg-accent py-14 lg:py-20'
        >
            <div className='container text-white'>
                <ContentSlider
                    slidesToShow={1}
                    navPosition='center'
                    containerBleedOff={true}
                >
                {carousel_slides && (carousel_slides.map((slide, index) => {
                    return(
                        <div className='text-center' key={index}>
                            <div className="flex justify-center pb-4 mx-auto max-w-[250px] max-h-[175px]">
                                <img src={slide.logo} alt='' className='object-contain' />
                            </div>
                            <div
                                className='prose prose-2xl pt-6 pb-8 mx-auto text-white'
                                dangerouslySetInnerHTML={{ __html: slide.content }}
                            />
                            <p className='text-xl tracking-widest pb-2'>{slide.client_name}</p>
                            <p className='text-sm tracking-widest'>{slide.client_role}</p>
                        </div>
                    )
                }))}
                </ContentSlider>
            </div>
        </motion.section>
    )
}
