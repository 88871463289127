import React from 'react';
import Asterisk from '../../../components/asterisk'

export default function BulletedList({ list }) {
    return (
    <ul className='prose prose-lg pt-4'>
        {list.map((item, index) => (
            <li key={index} className='relative pl-12 pb-2 not-prose'>
                <span className='absolute top-1.5 -left-0.5 w-[25] h-[25]'>
                    <Asterisk className='text-accent' />
                </span>
                {item.bullet ? (
                    <>{item.bullet}</>
                ) : (
                    <>{item}</>
                )}
            </li>
        ))}
    </ul>
    );
};
