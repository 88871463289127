import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import HeaderLogo from './graphics/HeaderLogo-2';
import Nav from './Nav';
import { motion, useScroll, useMotionValueEvent, stagger, AnimatePresence } from 'framer-motion';
import { Divide as Hamburger } from 'hamburger-react';

export default function Header(props) {
    const menuItems = [
        { name: 'Work', path: '/work/', delay: 0.35 },
        { name: 'People', path: '/people/', delay: 0.2 },
        { name: 'Careers', path: '/careers/', delay: 0.25 },
        { name: 'Latest', path: '/latest/', delay: 0.30 },
        { name: 'Contact', path: '/contact/', delay: 0.40 },
    ]
    const [atTop, setAtTop] = useState(true);
    const { scrollY } = useScroll();
    const [menuOpen, setMenuOpen] = useState(false);

    useMotionValueEvent(scrollY, 'change', (latest) => {
        const top = latest <= 120;
        if (atTop !== top) {
            setAtTop(top);
        }
    });

    const handleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const MenuItemMobile = (props) => {
        const linkStyles = 'block text-foreground transition-colors dark:hover:opacity-75 dark:active:opacity-100 text-3xl'
        return (
            <AnimatePresence>
            <motion.div
                layout
                initial={{ opacity: 0, y: -40, x: -20 }}
                exit={{ opacity: 0, y: -40, x: -20 }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={{
                    type: 'spring',
                    delay: props.delay,
                    stiffness: 200,
                    velocity: 10
                }}
            >
                <Link to={props.href} className={linkStyles}>
                    {props.children}
                </Link>
            </motion.div>
            </AnimatePresence>
        )
    }
    let { pathname } = useLocation();
    const [logoOpacity, setLogoOpacity] = useState('opacity-100')
    useEffect(() => {
        if (props.homepage) {
            setLogoOpacity('opacity-0')
        } else {
            setLogoOpacity('opacity-100')
        }
    }, [props.homepage])
    return (
        <motion.header
            id='site-header'
            className={`overflow-y-visible z-50 mb-0 fixed w-full top-0 ${props.homepage ? 'h-0' : ''}`}
            transition={{
                duration: 0.5,
            }}
            animate={{
                backgroundColor: atTop || props.homepage ? 'rgba(28, 31, 57, 0)' : 'rgba(28, 31, 57, 1)',
                // height: atTop ? 'auto' : '100px',
            }}
        >
            <div className='container'>

                <div className={`
                    relative z-10 grid grid-cols-2 items-center py-2
                    sm:grid-cols-3
                    md:py-6
                    lg:items-start
                    ${props.homepage ? 'h-0' : ''}
                `}>
                    <div className='xl:order-3 sm:col-span-2 md:col-span-1'>
                        <div className='xl:hidden'>
                            <Hamburger toggled={menuOpen} toggle={() => handleMenu()} color='#b57f68' rounded />
                        </div>
                    </div>
                    <div className='xl:order-1'>
                        <div className={`
                            max-w-xs mx-auto px-4
                            ${logoOpacity}
                        `}>
                            <HeaderLogo animating={!atTop} homepage={props.homepage} />
                        </div>
                    </div>
                    <div className={`hidden xl:block col-span-3 xl:col-span-1`}>
                        <Nav id='header-nav' />
                    </div>
                </div>
            </div>
            <AnimatePresence>
            {menuOpen && (
                <motion.div
                    className={`
                        w-screen h-screen absolute inset-0 bg-background/90 z-100 flex justify-center items-center origin-top-left
                    `}
                    layout
                    initial={{ opacity: 0, scale: 0 }}
                    exit={{ opacity: 0, scale: 0, delay: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.15,}}
                >
                    <nav className={`flex flex-col sans z-50 text-center items-center gap-8 md:gap-12 md:flex-row`}>
                        {menuItems.map((link) => <MenuItemMobile key={link.name} href={link.path} delay={link.delay} openContactForm={props.openContactForm}>{link.name}</MenuItemMobile>)}
                    </nav>
                </motion.div>
            )}
            </AnimatePresence>
        </motion.header>
    );
}
