import Logo from './graphics/logo';
import Social from './Social';
import Nav from './Nav';
import Address from './Address';
import Curve from './Curve';
import { Link } from 'react-router-dom';

export default function HeaderHome(props) {
    return (
        <header id='site-header-home' className='overflow-y-visible pb-8 z-30 height-sm:mb-24 relative'>
            <div className='bg-background'>
                <div className='container relative z-10 pt-6 grid md:grid-cols-3 md:px-8'>
                    <Social />

                    <div className='md:bg-transparent md:order-1 md:row-span-2'>{/* <Nav /> */}</div>
                    <div className='text-center md:order-3 md:text-right text-xs lg:text-base'>
                        <Address />
                        <div className='text-foreground-500/50 text-sm mt-2'>
                            <span className='block '>
                                © {new Date().getFullYear()} Archer Inc. All rights reserved.
                            </span>
                            <Link
                                className='block sm:inline-block sm:ml-1 underline hover:text-accent transition-colors'
                                to={'/privacy-policy/'}
                            >Privacy Policy</Link>
                        </div>
                    </div>
                    <div className='text-center md:order-2 md:row-span-2 pt-12 md:pt-8'>
                        <div className='max-w-xs mx-auto px-4'>
                            <Logo animated={props.animated} />
                            <p className='font-serif text-accent pt-12 text-3xl px-4'>Let’s go there.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Curve direction='down' />
        </header>
    );
}
