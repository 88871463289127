import React from 'react';

export default function SkeletonText({lines = 5}) {
	return (
        <div className='w-full flex flex-col gap-3 animate-pulse'>
            <div className='h-8 bg-foreground-300 rounded-md'></div>
			{
				[...Array(lines)].map((e, i) => (
					<div className='h-2 bg-foreground-300 rounded-md' key={i}></div>
				))
			}
        </div>
    );
}

