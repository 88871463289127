import React from 'react';

export default function Curve({fillClass = 'fill-background', direction}) {
    const directionClass = direction === 'down' ? 'rotate-180' : '';
    return (
        <svg
            width='375'
            height='18'
            viewBox='0 0 375 18'
            preserveAspectRatio='xMinYMin'
            className={`mx-auto ${fillClass} w-full h-auto ${directionClass} -mb-px`}
        >
            <path d='m0,17.65v.35h375v-.35c-123.93-23.54-251.07-23.54-375,0Z' />
        </svg>
    );
}
