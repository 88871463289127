/**
 * Block | Point Person Block
 *
 * Shows person in circle with person details to the right.
 *
 * @param
 * person
 * Person object from the authors custom post type.
 *
 * show_role
 * True/False as to whether or not job title should show.
 *
 * show_bio
 * True/False as to whether or not bio content should show.
 *
 * show_email
 * True/False as to whether or not email address should show.
 *
 * show_social
 * True/False as to whether or not social links should show.
 */

import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { motion } from 'framer-motion';
import LeaderCardHorizontal from '../../routes/people/components/LeaderCardHorizontal';

export default function PointPerson({ person, show_role, show_bio, show_email, show_social }) {
    const [leadershipArray, setLeadershipArray] = useState(null);

    useEffect(() => {
        api.getData(`authors/?include=${person.ID}&orderby=include&acf_format=standard&per_page=99`)
            .then((response) => {
                setLeadershipArray(response.data);
				// console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [person]);

    const makeLeader = (item) => {
		const bio = item.acf.leadership_bio !== '' ? item.acf.leadership_bio : item.content.rendered;

        // Make sure social doesn't break when nothing is set
        // We are assuming that we're only using LinkedIn for now AND only one social account per person
        let social = null;
        if (item.acf.author_social && item.acf.author_social.length > 0) {
            social = item.acf.author_social[0].asm_link;
        }
        
        return {
            id: item.id,
            name: item.title.rendered,
            title: item.acf.author_job_title,
            bio: bio,
            email: item.acf.email_address,
            social: social,
            image: {
                default: item.acf.headshot.url,
                hover: item.acf.headshot_hover.url,
            },
        };
    };
    
    return (
        leadershipArray && (
            <motion.section
                initial={{ opacity: 0, y: 40, rotate: 2 }}
                animate={{ opacity: 1, y: 0, rotate: 0 }}
                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                layout
                className={`block__point-person
                bg-foreground-100 pb-12 lg:pb-24`}
            >
                <div className='container overflow-visible'>
                    <div className='not-prose'>
                        {leadershipArray.map((item, index) => {
                            const leader = makeLeader(item);
                            return <LeaderCardHorizontal
                                        leader={leader}
                                        show_role={show_role}
                                        show_bio={show_bio}
                                        show_email={show_email}
                                        show_social={show_social}
                                        key={index}
                                    />;
                        })}
                    </div>
                </div>
            </motion.section>
        )
    );
}
