import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from '/src/components/button';

export default function JobContentDrawer({ open, setOpen, content }) {
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as='div' className='relative z-10' onClose={() => setOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-500'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-500'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-0 overflow-hidden'>
                    <div className='absolute inset-0 overflow-hidden'>
                        <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                            <Transition.Child
                                as={Fragment}
                                enter='transform transition ease-in-out duration-300'
                                enterFrom='translate-x-full'
                                enterTo='translate-x-0'
                                leave='transform transition ease-in-out duration-300'
                                leaveFrom='translate-x-0'
                                leaveTo='translate-x-full'
                            >
                                <Dialog.Panel className='pointer-events-auto relative w-screen max-w-prose'>
                                    <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl pb-6'>
                                        <div className='flex-1'>
                                            <div className='px-4 py-6 sm:px-6 bg-accent dark:bg-background-700'>
                                                <div className='flex items-center justify-between'>
                                                    <Dialog.Title className='fluid-lg font-semibold leading-6 text-white'>
                                                        {content.title}
                                                    </Dialog.Title>
                                                    <div className='ml-3 flex h-7 items-center'>
                                                        <button
                                                            type='button'
                                                            className='rounded-md p-1.5 text-white hover:text-accent-100 focus:outline-none focus:ring-2 focus:ring-accent-200 transition-colors'
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <span className='sr-only'>Close panel</span>
                                                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='relative mt-6 flex-1 px-4 sm:px-6'>{content.content}</div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
