import React from 'react'
import Button from './button'
import viewportGet from '../utils/viewportGet';
export default function DevTools(props) {
    // get current slug
    const slug = window.location.pathname.split('/').pop()

    return (
        <div className="fixed bottom-0 left-0 z-[60] text-xs font-mono font-bold text-black overflow-hidden [&>span]:px-2 [&>span]:py-1 flex gap-1 items-center">
            <span className="bg-yellow block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">default</span>
            <span className="bg-cyan hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">sm</span>
            <span className="bg-magenta hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden">md</span>
            <span className="bg-accent hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden">lg</span>
            <span className="bg-blue-500 hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden">xl</span>
            <span className="bg-green-500 hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block">2xl</span>
            { slug === 'people' && (<div><Button url="https://amcomdev1.wpengine.com/wp-admin/post.php?post=23&action=edit">Edit</Button></div>) }
            { slug === 'careers' && (<div><Button url="https://amcomdev1.wpengine.com/wp-admin/post.php?post=5852&action=edit">Edit</Button></div>) }
            <span className="text-yellow font-mono text-xs">
                <div>
                    {props.viewportX}<span className="text-yellow/50">px / </span>{props.viewportY}<span className="text-yellow/50">px</span>
                </div>
            </span>
        </div>

    )
}