import React, { useState } from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion, LayoutGroup } from 'framer-motion';
import CaseStudyCta from './CaseStudyCta';

export default function CaseStudyCard({ bgImage, image, title, description, cta }) {
    const [isOpen, setIsOpen] = useState(false);
	const [delayHandler, setDelayHandler] = useState(null)

	const handleMouseEnter = (event) => {
		setDelayHandler(setTimeout(() => {
            setIsOpen(true);
        }, 200))
    }

    const handleMouseLeave = (item) => {
		setIsOpen(false);
        clearTimeout(delayHandler)
    }

	return (
        <LayoutGroup>
            <div
                className='relative lg:min-h-[420] xl:min-h-[520] overflow-hidden group'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div
                    style={{ '--image-url': `url(${bgImage})` }}
                    className='bg-[image:var(--image-url)] bg-cover bg-no-repeat bg-bottom h-full'
                >
                    <div
                        style={{ '--image-url': `url(${image})` }}
                        className='flex flex-col justify-end bg-[image:var(--image-url)] bg-contain bg-no-repeat bg-top h-full'
                    >
                    <div className='flex flex-col justify-end'>
                        <motion.div
                            layout
                            transition={{
                                type: 'easeIn',
                                duration: 0.2,
                            }}
                            className='bg-background/80 px-5 py-8'
                        >
                            {description && (
                                <div className={`card-description font-sans text-lg xl:text-xl leading-8 mb-6 tracking-wide ${isOpen ? 'text-accent' : 'text-white'}`}>
                                    {parse(description)}
                                </div>
                            )}
                            {title && (
                                <h3 className='font-bold text-lg text-white dark:prose-invert prose'>
                                    {title}
                                </h3>
                            )}
                            <motion.div layout className={`
                                dark duration-200 delay-[200ms] ease-in transition-opacity
                                ${isOpen ? ' opacity-100 ' : 'opacity-0 '}
                            `}>
                                {isOpen ? (
                                    <CaseStudyCta link={cta} />
                                ) : null}
                            </motion.div>
                        </motion.div>
                    </div>
                    </div>
                </div>
            </div>
        </LayoutGroup>
    );
}
