import React, { Children, useState, useRef } from 'react';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module

const navButtonClass = 'border border-foreground-300 bg-foreground-100 rounded-full p-5 disabled:opacity-50 disabled:cursor-not-allowed'
const svgClass = 'w-6 h-6 text-accent'

export default function Carousel({ children }) {
    const [_, setInit] = useState();
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    return (
        <Swiper
            modules={[Navigation, A11y]}
            a11y
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}

            breakpoints={{
                0: {
                    slidesPerView: 1.25,
                    spaceBetween: 16,
                },
                640: {
                    slidesPerView: 2.25,
                },
                1024: {
                    slidesPerView: 3.25,
                    spaceBetween: 28,
                },
            }}
            className='container-bleed'
            onInit={() => setInit(true)}
			onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
        >
            {Children.map(children, (child, i) => {
                return (
                    <SwiperSlide key={`slide-${i}`}>
                        {child}
                    </SwiperSlide>
                );
            })}
            <div className='relative flex gap-8'>
                <button className={navButtonClass} ref={navigationPrevRef}><ChevronLeftIcon className={svgClass} /></button>
                <button className={navButtonClass} ref={navigationNextRef}><ChevronRightIcon className={svgClass} /></button>
            </div>
        </Swiper>
    );
};

//className='max-w-sm mr-4 md:mr-7'
