/**
 * These blocks are intended to be used with ACF flexible blocks.
 * WordPress will return them as an array and will display
 * the blocks in the order they were set on the backend.
 *
 * In admin: Archer Flexible Blocks
 *
 * Add any custom styles for the block to the block.css file.
 */

import React from 'react';

// Flexible Blocks
import './blocks.css';
import CaseStudies from './CaseStudies';
import Content from './Content';
import DetailedColumns from './DetailedColumns';
import HubspotForm from './HubspotForm';
import Logos from './Logos';
import PointPerson from './PointPerson';
import Quotes from './Quotes';
import BorderedCallout from './BorderedCallout';
import Video from './Video';
import TextImage from './TextImage'; // temporary differentiation from the case-study blocks

export default function Blocks(block) {
    // Grab accent if there is one
    const accent = block.accent ? block.accent : '';

    // since the object was sent as another object let's simplify its usage
    block = block.block;

    switch (block.acf_fc_layout) {
        case 'case_studies':
            return <CaseStudies relatedCaseStudies={block.related} />;
        case 'content':
            return <Content text_columns={block.text_columns} accent={accent} accentPos='top' />;
        case 'detailed_column_content':
            return (
                <DetailedColumns
                    section_header={block.section_header}
                    left_column={block.left_column}
                    right_column={block.right_column}
                />
            );
        case 'form':
            return <HubspotForm show_form={block.show_form} file_download={block.file_download} />;
        case 'logos':
            return <Logos title={block.title} description={block.description} slides={block.slides} />;
        case 'point_person':
            return (
                <PointPerson
                    person={block.person}
                    show_role={block.show_role}
                    show_bio={block.show_bio}
                    show_email={block.show_email}
                    show_social={block.show_social}
                />
            );
        case 'quotes':
            return <Quotes carousel_slides={block.carousel_slides} />;
        case 'text_image':
            return <BorderedCallout content={block.content} image={block.image} />;
        case 'video':
            return <Video video_embed={block.video_embed} video_placeholder={block.video_placeholder} />;
        case 'content_image':
            return (
                <TextImage
                    heading={block.heading}
                    headingStyle={block.heading_style}
                    content={block.content}
                    image={block.image}
                    imagePosition={block.image_position}
                    mobileImagePosition={block.mobile_image_position}
                    centered={block.center_content}
                    centeredVertical={block.center_content_vertically}
                />
            );
        default:
            return false;
    }
}
