import React from 'react';
import { Link } from 'react-router-dom';

export default function CaseStudyCta(cta) {
	const btnClasses = 'mt-6 btn btn-accent longtext text-md';
    return (
        <Link
            className={btnClasses}
            to={
                // Take the cta object passed down and remove the domain from the link
                cta.link.replace(/https?:\/\/[^\/]+/i, "")
            }
            state={{ prevPath: location.pathname }}
        >
            View Case Study
        </Link>
    );
}
