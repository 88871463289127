/**
 * Block | Detailed Column Content
 *
 * Intended for use with 2 columns.
 * Has a title above the column sections and places a border between columns.
 *
 * @param
 * section_header
 * User inputted title to appear above all other content in this block.
 *
 * left_column
 * Contains an array of WYSIWYG content from ACF that will show on the left side.
 *
 * right_column
 * Contains an array of WYSIWYG content from ACF that will show on the right side.
 */

import React from 'react';
import parse, { domToReact } from 'html-react-parser/dist/html-react-parser';
import { motion } from 'framer-motion';
import Starline from '../../routes/case-studies/components/Starline';
import BulletedList from '../../routes/case-studies/components/BulletedList';

export default function DetailedColumns({ section_header, left_column, right_column }) {

    // Replace bulleted lists returned from ACF WYSIWYG fields
    const replaceListBullets = {
        replace: ({ attribs, children }) => {
          if (!attribs) {
            return;
          }

          if (attribs.class === 'star-bullets') {
            // Remove any element that is not an li
            // Was seeing some random 'text' type content with only "\n" as their value being returned from WordPress
            const liChildrenOnly = children.filter(item => item.type === 'tag');

            // We only want the text in the children li elements,
            // not the entire li since our component will add its own elements
            let liChildrenContent = [];
            for (let i = 0; i < liChildrenOnly.length; i++) {
                liChildrenContent.push(liChildrenOnly[i].children[0]);
            }

            return <BulletedList list={domToReact(liChildrenContent, replaceListBullets)} />;
          }
        }
    };

    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className={`block__detailed-columns
            bg-foreground-100
            relative overflow-hidden
            py-10
            md:py-16
            md:pt-12
            `}
        >
            <div className='container relative z-10 overflow-hidden'>
                {section_header && (
                    <h2 className='text-3xl md:text-4xl text-accent font-normal pb-10 tracking-wide uppercase text-center'>
                        {section_header}
                    </h2>
                )}
                <div className='grid lg:grid-flow-col lg:grid-cols-2 gap-10 gap-x-24'>
                    <div className='max-w-2xl prose prose-lg'>
                        { parse(left_column, replaceListBullets) }
                    </div>

                    {/* Vertical border */}
                    <div className="hidden lg:inline-block absolute left-1/2 h-full min-h-[1em] w-0.5 self-stretch bg-foreground-500 opacity-100 dark:opacity-50" />

                    <div className='max-w-2xl prose prose-lg'>
                        { parse(right_column, replaceListBullets) }
                    </div>
                </div>
            </div>
            <Starline opacity='100' />
        </motion.section>
    )
}
