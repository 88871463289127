import React from 'react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import HeadshotPlaceholder from '/src/images/headshots/placeholder_woman.png';
import Social from '/src/components/Social';

export default function LeaderCard({leader}) {
	const {id, name, title, social, image} = leader;
	const getImage = (url) => url ? `${url}&width=300` : HeadshotPlaceholder
    return (
        <div id={id} className='flex flex-col items-center text-center'>
            <a href={social} target='_blank' title='LinkedIn'>
                <figure className='relative group border-4 border-foreground hover:border-accent transition-all duration-300 
                                    rounded-full w-44 md:w-64 max-w-[215px] aspect-square overflow-hidden mb-4 bg-white'>
                    <img
                        src={getImage(image.default)}
                        alt={`${name}'s headshot`}
                        className='absolute w-full h-auto rounded-full'
                    />
                    {/* <img
                        src={getImage(image.hover)}
                        alt=""
                        className='absolute w-full h-auto rounded-full opacity-0 group-hover:opacity-100 transition-opacity'
                    /> */}
                </figure>
            </a>
            <h3 className='text-xl lg:text-2xl text-accent-500 mb-2'>{name}</h3>
            <p className='text-lg text-background w-4/5 mb-3'
                dangerouslySetInnerHTML={{ __html: title }} />
            {social && (
                <Social
                    theme='gold'
                    selected-socials={[
                        {
                            name: 'LinkedIn',
                            url: social,
                            icon: faLinkedin,
                            text: `Hear ${name}'s thoughts on LinkedIn`,
                        },
                    ]}
                />
            )}
        </div>
    );
}
