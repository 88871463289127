import React from 'react';
import { ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useNavigate, Link } from 'react-router-dom';
import parse from 'html-react-parser/dist/html-react-parser';

export default function PostCard({ post }) {
    let linkUrl, linkText;
    let cats = 'branding, web design, video, outdoor';
    switch (post.type) {
        case 'page':
            linkText = 'Read More';
            if (post.parent) {
                linkUrl = `/${post.parent}/${post.slug}/`;
                break;
            }
            linkUrl = `/${post.slug}/`;
            break;
        case 'case-study':
            linkText = 'Explore Case Study';
            linkUrl = `/case-studies/${post.term}/${post.slug}/`;
            break;
        case 'post':
        default:
            linkText = 'Read Post';
            linkUrl = `/latest/${post.slug}/`;
            break;
    }

    return (
        <Link
            to={linkUrl}
            className={``}
            aria-label={`View post: ${parse(post.title)}`}
            class={`
                mx-auto hover:opacity-75 group
                ${post.thumbnail_orientation === 'landscape' ? ' sm:max-w-80 lg:max-w-[440px]' : ''}
                ${post.thumbnail_orientation === 'portrait' ? ' sm:max-w-60 lg:max-w-[330px]' : ''}
                ${post.thumbnail_orientation === 'extrawide' ? ' sm:max-w-[560px] lg:max-w-[760px]' : ''}
            `}
        >
                <figure>
                    <div
                        class={`
                            rounded-lg w-full relative overflow-hidden
                            ${post.thumbnail_orientation === 'landscape' ? ' max-w-72 sm:max-w-none ' : ''}
                            ${post.thumbnail_orientation === 'portrait' ? ' max-w-56 sm:max-w-none ' : ''}
                            ${post.thumbnail_orientation === 'extrawide' ? ' max-w-96 sm:max-w-none ' : ''}
                        `}
                    >

                        {
                            post.has_video_thumbnail === "Yes" ? <>
                                <div class="relative"><span class="block z-10 absolute inset-0 bg-accent-500"></span><img src={post.thumbnail_image ?? post.thumbnail_image} alt={``} class={`w-full h-auto block cursor-pointer relative z-10 max-w-none group-hover:opacity-0 duration-0`} width={post.thumbnail_image_width} height={post.thumbnail_image_height} />
                                <video class="block z-20 absolute inset-0 group-hover:opacity-50 w-full h-auto duration-300 cursor-pointer" autoPlay={true} loop={true} muted={true} playsInline={true} loading="lazy">
                                    <source src={post.video_url} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video></div>
                            </> : <div class="relative"><span class="block z-10 absolute inset-0 bg-accent-500"></span><img src={post.thumbnail_image_large} alt={``} class={`w-full h-auto block cursor-pointer duration-300 relative z-20 max-w-none group-hover:opacity-50`} width={post.thumbnail_image_width} height={post.thumbnail_image_height} /></div>
                        }
                    </div>
                    <figcaption class="flex flex-col gap-2 mt-2 text-background-500">
                        <p className="categories">
                            {
                                post.tags !== '' ? post.tags.split(',').map((tag, i) => {
                                    return <span key={i} className="mr-4 font-bold text-accent-700 text-xs uppercase category">{tag}</span>
                                }) : ''
                            }
                        </p>
                        <h3 id={post.title} class="font-bold text-2xl">
                            {parse(post.title)}
                        </h3>
                        {post.excerpt && <div className='text-base'>{parse(post.excerpt)}</div>}
                        <div className='flex align-center'>
                            <div className='group-hover:text-background flex items-center mb-6 transition-colors'>
                                {linkText + ' '}
                                <ArrowUpRightIcon className='group-hover:text-background ml-2 w-5 h-5 text-accent transition-colors group-hover:animate-pulse' />
                            </div>
                        </div>
                    </figcaption>
                </figure>
        </Link>
    );
}
