import React from 'react';
import parse from 'html-react-parser/dist/html-react-parser';
import CaseStudyCta from './CaseStudyCta';

export default function CaseStudySlide({ bgImage, image, title, description, cta }) {
    
    return (
        <div
            style={{ '--image-url': `url(${bgImage})` }}
            className='flex flex-col justify-end h-full relative group overflow-hidden min-h-[420]
                bg-[image:var(--image-url)]
                bg-no-repeat bg-bottom bg-cover'
        >
            <div
                style={{ '--image-url': `url(${image})` }}
                className='h-full flex flex-col justify-end
                    bg-[image:var(--image-url)]
                    bg-no-repeat bg-bottom bg-contain'
            >
                <div className='bg-background/50 px-5 py-8 text-white'>
                    {title && <h3 className='font-sans text-2xl mb-2 leading-7'>{title}</h3>}
                    {description && <div className='card-description text-base prose text-white'>{parse(description)}</div>}
                    {cta && <CaseStudyCta link={cta} />}
                </div>
            </div>
        </div>
    );
}
