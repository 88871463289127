import React from 'react';

export default function Address() {
    return (
        <address className=' text-foreground-500 not-italic text-sm'>
            <span className='block'>65 Union Avenue, Suite 500</span>
            <span className='block'>Memphis, TN 38103</span>
            <span className='block'>901-523-2000</span>
        </address>
    );
}
