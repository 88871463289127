import React from 'react';
import { Link } from 'react-router-dom';

export default function ContentCta(cta) {
	const btnClasses = 'mt-4 btn btn-accent longtext text-sm';

    return cta.internal_link ? (
        <Link
            className={btnClasses}
            to={
                cta.choose_post.post_type === 'post'
                    ? `/latest/${cta.choose_post.post_name}/`
                    : cta.choose_post.post_name
            }
            state={{ id: cta.choose_post.ID, prevPath: location.pathname }}
        >
            {cta.link_text ? cta.link_text : cta.choose_post.post_title}
        </Link>
    ) : (
        <a className={btnClasses} href={cta.url} title='Learn More' target='_blank'>
            {cta.link_text}
        </a>
    );
}
