/**
 * Block | Case Studies
 *
 * @param
 * relatedCaseStudies (ACF name = related)
 * Relationship to get the Case Study post object.
 */

import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import { motion } from 'framer-motion';
import CaseStudySlider from '../../routes/case-studies/components/CaseStudySlider';
import CaseStudySlide from '../../routes/case-studies/components/CaseStudySlide';
import CaseStudyCard from '../../routes/case-studies/components/CaseStudyCard';

const bgStars01 = new URL('../../images/bg_stars_box_01.jpg', import.meta.url);
const bgStars02 = new URL('../../images/bg_stars_box_02.jpg', import.meta.url);
const bgStars03 = new URL('../../images/bg_stars_box_03.jpg', import.meta.url);
const backgrounds = [
    bgStars01, bgStars02, bgStars03
];

export default function CaseStudies({ relatedCaseStudies }) {
    const [loaded, setLoaded] = useState(false);
    const [CaseStudyArray, setCaseStudyArray] = useState(null);
    const [minContainerHeight, setMinContainerHeight] = useState(0);

    const updateMinContainerHeight = () => {
        const rows = document.querySelectorAll('.card-row');
        rows.forEach((row) => {
            const elements = row.querySelectorAll('.card-description');

            // reset text height to recalculate an accurate height
            elements.forEach((element) => {
                element.style.minHeight = `auto`;
            });

            // Calculate new height
            let maxElementHeight = 0;
            elements.forEach((element) => {
                maxElementHeight = Math.max(maxElementHeight, element.clientHeight);
            });

            // Set height
            setMinContainerHeight(maxElementHeight);
            elements.forEach((element) => {
                element.style.minHeight = `${maxElementHeight}px`;
            });
        });
    };

    useEffect(() => {
        api.getData(`case-study/?include=${relatedCaseStudies.map(item => ( item.ID + ',' ))}&orderby=include&acf_format=standard&_embed&per_page=99`)
            .then((response) => {
                // console.log(response.data);
                setCaseStudyArray(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(false);
            });
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateMinContainerHeight);
        
        return () => {
            window.removeEventListener('resize', updateMinContainerHeight);
        };
    }, [minContainerHeight]);

    useEffect(() => {
        if (loaded) {
            updateMinContainerHeight();
        }
    }, [loaded]);

    return (
        <>
        {!loaded ? (
            <div className='h-screen'></div>
        ) : (
            <motion.section
                initial={{ opacity: 0, y: 40, rotate: 2 }}
                animate={{ opacity: 1, y: 0, rotate: 0 }}
                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                layout
                className={`bg-foreground-100 py-12 md:py-16 lg:py-28`}
            >
            <div className='container'>
                <div className="prose not-prose text-center mx-auto">
                    <h2 className='text-4xl text-accent uppercase tracking-wide mb-12 md:mb-16'>Case Studies</h2>
                </div>
                <div className='card-row lg:hidden'>
                    <CaseStudySlider>
                        {CaseStudyArray &&
                            CaseStudyArray.map((caseStudy, index) => (
                                <CaseStudySlide
                                    key={index}
                                    image={`${caseStudy._embedded['wp:featuredmedia'][0].source_url}&w=800`}
                                    bgImage={`${backgrounds[index % 3]}`}
                                    title={caseStudy.title.rendered}
                                    description={caseStudy.acf.headline}
                                    cta={caseStudy.link}
                                />
                            ))}
                    </CaseStudySlider>
                </div>
                <div className='card-row hidden lg:grid md:grid-cols-2 lg:grid-cols-3'>
                    {CaseStudyArray &&
                        CaseStudyArray.map((caseStudy, index) => {
                            return (
                                <CaseStudyCard
                                    key={index}
                                    image={`${caseStudy._embedded['wp:featuredmedia'][0].source_url}&w=800`}
                                    bgImage={`${backgrounds[index % 3]}`}
                                    title={caseStudy.title.rendered}
                                    description={caseStudy.acf.headline}
                                    cta={caseStudy.link}
                                />
                            );
                        })}
                </div>
            </div>
            </motion.section>
        )}
        </>
    )
}
