import React from 'react';
import Featured from './Featured';
import PostsCarousel from './PostsCarousel';
import PostsGrid from './PostsGrid';
import api from '/src/utils/api';
import makePosts from '/src/utils/makePosts';

export default function BlogPageContent() {
    const [featured, setFeatured] = React.useState(null);
    const [posts, setPosts] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);

	const buildPosts = (data) => {
		const calledPosts = makePosts(data);
		let featIndex = calledPosts.findIndex(({ sticky }) => sticky === true);
		featIndex = featIndex === -1 ? 0 : featIndex;

		setFeatured(calledPosts[featIndex]);
		calledPosts.splice(featIndex, 1);
		setPosts(calledPosts);
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
    React.useEffect(() => {
        api.getData('posts?per_page=100&_embed&acf_format=standard')
            .then((response) => {
				// console.log(response.data)
				buildPosts(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);
    return (
        <>
            {featured && <Featured post={featured} loaded={loaded} />}
            {/* {<PostsCarousel posts={loaded ? posts : [...Array(3)]} loaded={loaded} />} */}
			<PostsGrid posts={loaded ? posts : [...Array(3)]} loaded={loaded} />
        </>
    );
}
