import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';

const buttonClasses =
    'flex items-center text-sm bg-foreground-100 hover:bg-accent text-background-400 hover:text-white border border-foreground hover:border-accent rounded-lg p-2 transition-colors group';
const buttonSvgClasses = 'w-5 h-5 text-accent flex-shrink-0 group-hover:text-white transition-colors';



export default function Share() {
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		}
	});

	const buttonAction = (target) => {
		return () => {
			if (target === location.href) {
				navigator.clipboard.writeText(target);
				setIsCopied(true);
			} else {
				window.open(target, '_blank');
			}
		};
	};
	
	let shares = [
		{
			name: 'copy',
			url: location.href,
			icon: faClone,
			text: 'Copy Link',
		},
		{
			name: 'twitter',
			url: `https://twitter.com/intent/tweet?text=${location.href}`,
			icon: faTwitter,
			text: 'Share on Twitter',
		},
		{
			name: 'facebook',
			url: `https://www.facebook.com/sharer/sharer.php?u=${location.href}`,
			icon: faFacebook,
			text: 'Share on Facebook',
		},
		{
			name: 'linkedin',
			url: `https://www.linkedin.com/shareArticle?mini=true&url=${location.href}`,
			icon: faLinkedin,
			text: 'Share on LinkedIn',
		},
	];

    return (
        <div className='flex gap-2'>
            {shares.map((share) => {
				let isCopy = share.name === 'copy'
                return (<button key={share.name} className={buttonClasses} aria-label={share.text} onClick={buttonAction(share.url)} role={isCopy ? 'button' : 'link'}>
                    <FontAwesomeIcon icon={isCopy && isCopied ? faCircleCheck : share.icon} className={buttonSvgClasses} /><span className={isCopy ? 'ml-2' : 'sr-only'}>{isCopy && isCopied ? 'Link Copied' : share.text}</span>
                </button>)
			})}
        </div>
    );
}
