import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import HeadshotPlaceholder from '/src/images/headshots/placeholder_woman.png';
// import Social from '/src/components/Social';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function ContactCard({person}) {
	const {id, name, title, email, social, image, heading} = person;
	const getImage = (url) => url ? `${url}&width=300` : HeadshotPlaceholder;
    const updatedTitle = title.replace(/<br\s*\/?>/gi, ' '); // Used to remove <br> brought in from WP CMS since we don't need to follow that here
    return (
        <div id={id} className='flex flex-row gap-3 sm:gap-8'>
            <a href={social} target='_blank' title='LinkedIn'>
                <figure className='relative group border-[5px] border-foreground hover:border-accent transition-all duration-300 
                                    rounded-full w-44 md:w-64 max-w-[115px] sm:max-w-[155px] aspect-square overflow-hidden mb-4 bg-white'>
                    <img
                        src={getImage(image.default)}
                        alt={`${name}'s headshot`}
                        className='absolute w-full h-auto rounded-full'
                    />
                </figure>
            </a>
            <div className='flex flex-col content-start w-full'>
                <h3 className='text-2xl sm:text-3xl text-accent-500 mb-1 sm:mb-3'>{heading}</h3>
                <p className='text-base sm:text-xl text-background w-full sm:w-4/5'>{name}</p>
                <p className='text-base sm:text-xl leading-none text-background w-full sm:w-4/5 mb-1 sm:mb-3'>{updatedTitle }</p>
                <div className='flex gap-x-3 items-center'>
                    <div className='group'>
                        {/* {social && (
                            <Social
                                theme='gold'
                                selected-socials={[
                                    {
                                        name: 'Email',
                                        url: social,
                                        icon: faEnvelope,
                                        text: `Reach out to ${name}`,
                                    },
                                ]}
                            />
                        )} */}
                        <a
                            className='text-slate-900 group-hover:text-accent transition-colors'
                            aria-label={`Reach out to ${name}`}
                            href={`mailto:${email}`}
                            title='Email'
                        >
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className={`w-6 h-6 flex-shrink-0`}
                            />
                            <span className='sr-only'>{`Reach out to ${name}`}</span>
                        </a>
                    </div>
                    {name && name.trim() === 'Marc Girard' && (
                        <a href='/careers/' className='text-sm text-accent-500 underline -mt-1'>Open Roles</a>
                    )}
                </div>
            </div>
        </div>
    );
}
