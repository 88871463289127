import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
export default function SEO({ title, description, name, type }) {
    const defaultDescription = 'Archer Malmo is an integrated marketing and advertising agency bringing together creativity, data and technology to drive results.'
    const newDescription = (description ? description : defaultDescription)
    const newTitle = (title ? title : 'Archer')
    return (
        <HelmetProvider>
            <Helmet>
                {/* Standard metadata tags */}
                <title>{newTitle}</title>
                <meta name='description' content={newDescription} />
                {/* End standard metadata tags */}
                {/* Facebook tags */}
                <meta property='og:type' content={type} />
                <meta property='og:title' content={newTitle} />
                <meta property='og:description' content={newDescription} />
                {/* End Facebook tags */}
                {/* Twitter tags */}
                <meta name='twitter:creator' content={name} />
                <meta name='twitter:card' content={type} />
                <meta name='twitter:title' content={newTitle} />
                <meta name='twitter:description' content={newDescription} />
                {/* End Twitter tags */}
            </Helmet>
        </HelmetProvider>
    );
}
