import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const socials = [
    {
        name: 'linkedin',
        url: 'https://www.linkedin.com/company/wearearcher_/',
        icon: faLinkedin,
        text: 'Hear our thoughts on LinkedIn',
    },
    {
        name: 'instagram',
        url: 'https://www.instagram.com/wearearcher_',
        icon: faInstagram,
        text: "See what we're up to on Instagram",
    },
    {
        name: 'youtube',
        url: 'https://www.youtube.com/@wearearcher',
        icon: faYoutube,
        text: 'Subscribe to us on YouTube',
    },
];

export default function Social(props) {
    let socialsArray = props['selected-socials'] ? props['selected-socials'] : socials;
    return (
        <div className='flex gap-6 justify-center md:order-3 md:justify-end mb-4 md:mb-0'>
            {socialsArray.map((social) => (
                <a
                    key={social.name}
                    className='group flex items-center justify-center'
                    aria-label={social.text}
                    href={social.url}
                    title={social.name}
                    target='_blank'
                    rel='noreferrer'
                >
                    <FontAwesomeIcon
                        icon={social.icon}
                        className={`w-8 h-8 flex-shrink-0 text-foreground ${props.theme === 'gold' ? 'group-hover:text-accent' : 'group-hover:text-white'} transition-colors`}
                    />
                    <span className='sr-only'>{social.text}</span>
                </a>
            ))}
        </div>
    );
}
