import React, { useEffect, useState } from 'react';
import Wrapper from '/src/components/wrapper';
import PageLead from '/src/components/PageLead';
import api from '/src/utils/api';
import Seo from '/src/components/Seo';
import { motion } from 'framer-motion';
import api from '/src/utils/api';

const defaultSeo = {
    title: 'Sitemap | Archer',
    description: 'We hope you find what you are looking for.',
};

export default function Sitemap(props) {
    const [loaded, setLoaded] = useState(false);
	const [seo, setSeo] = useState(defaultSeo);

    const [posts, setPosts] = useState([]);
    const [pages, setPages] = useState([]);
    const [caseStudies, setcaseStudies] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        api.getData('pages?_embed?&per_page=99&orderby=parent&order=asc')
            .then((response) => {
                // console.log(response.data);
                setPages(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });

        api.getData(`case-study?&per_page=99&orderby=title&order=asc`)
            .then((response) => {
                // console.log(response.data);
                setcaseStudies(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });

            api.getData('posts?&per_page=99&orderby=date&order=desc')
            .then((response) => {
				// console.log(response.data)
				setPosts(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });

    }, []);

	return (
		<>
		<Seo title={seo.title} description={seo.description} />
		<Wrapper>
			{!loaded ? (
				<div className='h-screen'></div>
			) : (
				<motion.div
					layout
					initial={{ opacity: 0 }}
					exit={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0, delay: 0 }}
				>

                    <motion.section
                        initial={{ opacity: 0, y: 40 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2, delay: 0.2 }}
                        layout
                        className='relative transparent pt-44'
                    >
                        <PageLead
                            title='Sitemap'
                            subtitle=''
                            text=''
                            minHeight={400}
                            noMaxWidth={true}
                        />
                    </motion.section>

                    <div className='container pb-44'>
                        <div className='max-w-4xl mx-auto pb-12'>
                            <h2 className='font-display text-accent text-3xl mb-4'>Pages</h2>
                            <ul>
                                {pages.map(page => (
                                <li key={page.id} className='underline mb-1'>
                                    <a href={`${page.link}`}>{page.title.rendered}</a>
                                </li>
                                ))}
                            </ul>
                        </div>

                        <div className='max-w-4xl mx-auto pb-12'>
                            <h2 className='font-display text-accent text-3xl mb-4'>Case Studies</h2>
                            <ul>
                                {caseStudies.map(caseStudy => (
                                <li key={caseStudy.id} className='underline mb-1'>
                                    <a href={`${caseStudy.link}`}>{caseStudy.title.rendered}</a>
                                </li>
                                ))}
                            </ul>
                        </div>

                        <div className='max-w-4xl mx-auto'>
                            <h2 className='font-display text-accent text-3xl mb-4'>Posts</h2>
                            <ul>
                                {posts.map(post => (
                                <li key={post.id} className='underline mb-1'>
                                    <a href={`${post.link}`}>{post.title.rendered}</a>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    
				</motion.div>
				)}
		</Wrapper>
		</>
	)
}
