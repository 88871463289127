/**
 * Block | Logos Block
 *
 * @param
 * title
 * Text title
 *
 * description
 * Text area content
 *
 * slides
 * Repeater of logo slides
 *  - slide_title ( text )
 *  - slide_desc ( text )
 *  - slide_logos ( relationship with client CPT )
 *  - slide_logos ( relationship with client CPT )
 */

import React, {useState, useEffect} from 'react';
import api from '../../utils/api';
import React, {useState, useEffect} from 'react';
import api from '../../utils/api';
import { motion } from 'framer-motion';
import ContentSlider from '../../routes/careers/components/ContentSlider';
import Bracket from '/src/images/SVG/bracket.svg';
import ContentSlider from '../../routes/careers/components/ContentSlider';
import Bracket from '/src/images/SVG/bracket.svg';

export default function Logos({ title, description, slides }) {
    const [loaded, setLoaded] = useState(false);
    const [LogosArray, setLogosArray] = useState(null);

    useEffect(() => {
        // Create an array of all the ids for each slide
        const logoItems = slides.map((slide, slideIndex) => (
            slide.slide_logos.map((slide_logo, logoIndex) => (
                slide_logo.ID
            ))
        ));
        // console.log(logoItems);

        // Turn that array into a single dimentional array we can use to add to the api query
        const clientQuery = logoItems.reduce((accumulator, innerArray) => {
            return accumulator.concat(innerArray);
        }, []);

        // Get all the client custom post types that will have our logo as its featured image
        api.getData(`clients/?include=${clientQuery.map(itemID => ( itemID + ',' ))}&orderby=include&acf_format=standard&_embed&per_page=99`)
            .then((response) => {
                // console.log(response.data);
                setLogosArray(response.data);
                setLoaded(true);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(false);
            });
    }, []);

    return (
        <>
        {!loaded ? (
            <div className='h-screen'></div>
        ) : (
            <motion.section
                initial={{ opacity: 0, y: 40, rotate: 2 }}
                animate={{ opacity: 1, y: 0, rotate: 0 }}
                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                layout
                className={`bg-foreground-100 py-10`}
            >
                <div className='container text-black text-center'>
                    <div className='mb-8'>
                        {title && (
                            <h2 className='text-accent tracking-wide text-3xl xl:text-4xl uppercase pb-8'>{title}</h2>
                        )}
                        {description && (
                            <div
                                className='prose prose-lg mx-auto'
                                dangerouslySetInnerHTML={{ __html: description }}
                            />
                        )}
                        <div className='relative p-8
                                       before:bg-foreground
                                       before:w-[200px]
                                       before:h-[2px]
                                       before:absolute
                                       before:top-1/2
                                       before:left-1/2
                                       before:-translate-x-1/2
                                       '/>
                    </div>
                    <ContentSlider
                        slidesToShow={1}
                        navPosition='center'
                        containerBleedOff={true}
                        loop={true}
                    >
                    {slides && (slides.map((slide, index) => {
                        return(
                        <div className='text-center mx-auto pb-16' key={index}>
                            <div className='flex gap-x-12 items-center justify-center pb-12'>
                                <div className='flex h-full items-center justify-center debug-purple'>
                                    <img src={Bracket} alt='' className='' width='24' height='64' />
                                </div>
                                <div>
                                    <h3 className='text-accent tracking-wide text-2xl xl:text-4xl font-normal'><em>{slide.slide_title}</em></h3>
                                    <p className='prose prose-lg mx-auto'>{slide.slide_desc}</p>
                                </div>
                                <div className='flex h-full items-center justify-center debug-purple'>
                                    <img src={Bracket} alt='' className='rotate-180' width='24' height='64' />
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-center items-center gap-4 md:gap-10 max-w-7xl mx-auto">
                                {slide.slide_logos.map((slide_logo, logoIndex) => {
                                    // Find the matching logo in logosArray where id matches slide_logo.ID
                                    const matchingLogo = LogosArray.find(client => client.id === slide_logo.ID);

                                    // Check if a matching logo was found
                                    if (matchingLogo) {
                                        // Get the featured image from the object and show the image
                                        const featuredImageURL = matchingLogo._embedded['wp:featuredmedia'][0].source_url;

                                        return (
                                            <div className="w-[46%] md:w-[30%] p-4" key={logoIndex}>
                                                <img
                                                    src={featuredImageURL}
                                                    alt={slide_logo.post_title}
                                                    className="w-full md:w-44 max-w-[240px] mx-auto"
                                                />
                                            </div>
                                        );
                                    }

                                    return null; // Return null if no matching logo is found
                                })}
                            </div>
                        </div>
                        )
                    }))}
                    </ContentSlider>
                </div>
            </motion.section>
        )}
        </>
    )
}
