import React, { Children, useState, useRef } from 'react';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module

const navButtonClass =
    'border border-foreground-300 bg-foreground-100 rounded-full p-5 disabled:opacity-50 disabled:cursor-not-allowed';
const svgClass = 'w-6 h-6 text-accent';

export default function ContentSlider ({ children, slidesToShow, navPosition, containerBleedOff, loop }) {
    const [_, setInit] = useState();
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    return (
        <Swiper
            modules={[Navigation, A11y]}
            a11y
            navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            breakpoints={{
                0: {
                    slidesPerView: !containerBleedOff ? 1.3 : 1,
                    spaceBetween: 4,
					grid: {rows: 1},
                },
                640: {
                    slidesPerView: slidesToShow ? slidesToShow : 2.3,
                },
            }}
            className={`${!containerBleedOff ? 'container-bleed' : ''}`}
            onInit={() => setInit(true)}
            onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
            loop={loop ? true : false}
        >
            {Children.map(children, (child, i) => {
                return (
                    <SwiperSlide className='!h-auto' key={`slide-${i}`}>
                        {child}
                    </SwiperSlide>
                );
            })}
            <div className={`relative flex gap-4 my-4${navPosition === 'center' ? ' justify-center' : ''}`}>
                <button className={navButtonClass} ref={navigationPrevRef}>
                    <ChevronLeftIcon className={svgClass} />
                </button>
                <button className={navButtonClass} ref={navigationNextRef}>
                    <ChevronRightIcon className={svgClass} />
                </button>
            </div>
        </Swiper>
    );
};
