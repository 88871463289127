import { Suspense, lazy, useState, useEffect } from 'react';
import viewportGet from '../../utils/viewportGet';
const Mobile = lazy(() => import('./mobile'));
const Desktop = lazy(() => import('./desktop'));
import api from '../../utils/api';
import Seo from '/src/components/Seo';
import makeSeo from '../../utils/makeSeo';

const defaultSeo = {
    title: 'Let’s go there. | Archer',
    description:
        'Archer can guide your brand through uncharted territory or help you map a path to increased productivity. Let’s go there. See how Archer can work for you.',
};

export default function HomePage(props) {

    const [seo, setSeo] = useState(defaultSeo);
    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title.replace('&#039;', "'"),
            description: newSeo.description
        });
    };

    useEffect(() => {
        api.getData('pages/15?acf_format=standard')
            .then((response) => {
                getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <Suspense
            fallback={
                <div className='text-xl text-center text-white h-screen flex flex-col fixed inset-0 z-[999] items-center justify-center'>
                    Loading...
                </div>
            }
        >
            <Seo title={seo.title} description={seo.description} />
            {viewportGet('x') > 768 ? (
                <Desktop openContactForm={props.openContactForm} viewportY={props.viewportY} peopleList={props.peopleList} />
            ) : (
                <Mobile openContactForm={props.openContactForm} viewportY={props.viewportY} peopleList={props.peopleList} />
            )}
        </Suspense>
    );
}

