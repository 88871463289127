import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Nav({ className, isFullscreen, footer }) {
    let { pathname } = useLocation();

    return (
        <nav className={`
            flex flex-col items-center z-50
			${isFullscreen ? 'gap-8 md:gap-12' : 'gap-4 md:items-start'}
			${footer ? 'grid grid-cols-1 md:grid-cols-2 gap-4 md:text-left' : 'md:flex-row md:gap-8'}
            ${className ? className : ''}
        `}>
            {[
                //{ name: "Home", path: "/" },
                { name: 'Work', path: '/work/' },
                { name: 'People', path: '/people/' },
                { name: 'Careers', path: '/careers/' },
                { name: 'Latest', path: '/latest/' },
                { name: 'Contact', path: '/contact/' },
            ].map((link) => (
                <Link
                    key={link.name}
                    className={`text-foreground transition-colors hover:text-accent dark:hover:opacity-75 dark:active:opacity-100
						${isFullscreen ? 'text-3xl' : 'text-xl sm:text-lg'}
						${pathname === link.path.split('#')[0] ? '!text-accent-500' : ''}`}
                    to={link.path}
                >
                    {link.name}
                </Link>
            ))}
        </nav>
    );
}
