import React from 'react';

export default function Asterisk(props) {
    return(
        <svg viewBox='0 0 92 92' fill='none' xmlns='http://www.w3.org/2000/svg' className={props.className}>
            <path
                d='M64.19 37.03L74.23 22.74C74.56 22.27 74.5 21.56 74.09 21.16L70.33 17.4C69.93 17 69.21 16.93 68.75 17.26L54.35 27.36C53.41 28.02 52.54 27.62 52.41 26.48L49.66 1.11C49.6 0.5 49.04 0 48.43 0H43.05C42.44 0 41.89 0.5 41.82 1.1L39.07 26.47C38.95 27.61 38.07 28.01 37.13 27.35L22.73 17.25C22.26 16.92 21.55 16.98 21.15 17.39L17.4 21.14C17 21.54 16.93 22.26 17.26 22.72L27.3 37.01C27.96 37.95 27.57 38.82 26.43 38.95L1.1 41.8C0.5 41.87 0 42.42 0 43.03V48.41C0 49.02 0.5 49.57 1.1 49.64L26.56 52.36C27.71 52.48 28.11 53.36 27.44 54.3L17.26 68.73C16.93 69.2 16.99 69.91 17.4 70.32L21.15 74.07C21.55 74.47 22.27 74.54 22.73 74.21L37.13 64.12C38.07 63.46 38.94 63.86 39.07 65L41.82 90.37C41.89 90.98 42.44 91.47 43.05 91.47H48.43C49.04 91.47 49.59 90.97 49.66 90.37L52.41 64.99C52.53 63.85 53.4 63.45 54.34 64.11C58.13 66.76 62.74 70 68.74 74.2C69.21 74.53 69.93 74.47 70.33 74.07L74.09 70.31C74.49 69.91 74.56 69.2 74.23 68.73L64.2 54.29C63.55 53.35 63.94 52.48 65.08 52.36L90.38 49.65C90.99 49.58 91.48 49.03 91.48 48.42V43.04C91.48 42.43 90.99 41.88 90.38 41.81L65.05 38.96C63.91 38.83 63.52 37.96 64.18 37.02L64.19 37.03Z'
                fill='currentColor'
            />
        </svg>
    );
}
