/**
 * These blocks are intended to be used with ACF flexible blocks.
 * WordPress will return them as an array and will display
 * the blocks in the order they were set on the backend.
 *
 * In admin: Case Study Flexible Blocks
 *
 * Add any custom styles for the block to the CSBlocks.css file.
 */

import React from 'react';

// Flexible Blocks
// import './CSBlocks.css';
import ChallengeSolution from './ChallengeSolution';
import CSContent from './CSContent';
import FullWidthImage from './FullWidthImage';
import Hero from './Hero';
import ImageSlider from './ImageSlider';
import Quotes from './Quotes';
import RelatedContent from './RelatedContent';
import Results from './Results';
import TextImage from './TextImage';
import Video from './Video';

export default function CSBlocks(block, lastItem) {
    // console.log(block);

    // since the object was sent as another object let's simplify its usage
    block = block.block;

    switch (block.acf_fc_layout) {
        case 'challenge_solution':
            {/** Renamed to Staggered Content Block */}
            return(
                <ChallengeSolution
                    headingTop={block.top_heading}
                    headingStyleTop={block.top_heading_style}
                    headingBot={block.bottom_heading}
                    headingStyleBot={block.bottom_heading_style}
                    challengeDescription={block.challenge_description}
                    challengeImage={block.challenge_image}
                    solutionDescription={block.solution_description}
                    solutionImage={block.solution_image}
                    stagger={block.stagger_images}
                />
            )
        case 'content':
            return(
                <CSContent
                    heading={block.heading}
                    text_columns={block.text_columns}
                />
            )
        case 'fullwidth_image':
            return(
                <FullWidthImage
                    image={block.image}
                    imageMobile={block.image_mobile}
                    fullBleed={block.image_full_bleed}
                />
            )
        case 'hero':
            return(
                <Hero
                    accentImage={block.accent_image}
                    clientName={block.client_name}
                    headline={block.headline}
                    description={block.intro}
                />
            )
        case 'image_slider':
            return(
                <ImageSlider
                    images={block.images}
                    sliderOnDesktop={block.slider_on_desktop}
                    sliderOnMobile={block.slider_on_mobile}
                />
            )
        case 'related_content':
            return(
                <RelatedContent
                    relatedContent={block.related_content}
                />
            )
        case 'results_block':
            {/** Renamed to Bulleted Content Block */}
            return(
                <Results
                    heading={block.heading}
                    headingStyle={block.heading_style}
                    description={block.results_description}
                    image={block.results_image}
                    imagePosition={block.image_position}
                    fullBleed={block.image_full_bleed}
                    bullets={block.results_bullets}
                    additionalContent={block.additional_content}
                    descriptionBottom={block.results_description_after_bullets}
                />
            )
        case 'quotes':
            return(
                <Quotes
                    carousel_slides={block.carousel_slides}
                    lastItem={lastItem}
                />
            )
        case 'text_image':
            {/** Renamed to Content with Image Block */}
            return(
                <TextImage
                    heading={block.heading}
                    headingStyle={block.heading_style}
                    content={block.content}
                    image={block.image}
                    imagePosition={block.image_position}
                    mobileImagePosition={block.mobile_image_position}
                    centered={block.center_content}
                    centeredVertical={block.center_content_vertically}
                />
            )
        case 'video':
            return(
                <Video
                    videoSource={block.video_embed}
                    placeholderImage={block.video_placeholder}
                />
            )
        default:
            return(false);
    }
}
