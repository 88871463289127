import React from 'react';
const bgBlob = new URL('/src/images/postBG_blob.png?as=webp&quality=45&width=2000', import.meta.url);
const bgBlobSharp = new URL('/src/images/starline.png?as=webp&quality=45&width=2000', import.meta.url);

export default function Starline(props) {
    return (
        <img src={props.sharp ? bgBlobSharp : bgBlob}
             alt=''
             className={`absolute
                -left-1/2
                top-[45%]
                scale-[3.0]
                w-full
                opacity-${props.opacity ? props.opacity : 100}
                select-none
                z-0
                sm:left-0
                sm:top-1/4
                sm:scale-125
                lg:scale-100
                md:top-0
                md:-bottom-3/4`}
             draggable='false' />
    );
};