import Wrapper from '/src/components/wrapper';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser/dist/html-react-parser';
import '/src/wordpress.css';
import Share from './components/Share';
import api from '/src/utils/api';
import { motion } from 'framer-motion';
import Seo from '/src/components/Seo';
import makeSeo from '../../utils/makeSeo';
import PointPerson from '/src/components/blocks/PointPerson';

const bgStar = new URL('../../images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);
const defaultFeatImg = new URL('../../images/post-default.jpg', import.meta.url);

const defaultSeo = {
    title: 'The Latest from Archer | Archer',
    description: 'Here, you’ll find pieces by and about Archer, an employee-owned agency. Find thought leadership articles, industry accolades and more.',
};

export default function Post(props) {
    const [post, setPost] = useState(null);
	const [postAuthor, setPostAuthor] = useState(null)
    const [loaded, setLoaded] = useState(false);
    const [seo, setSeo] = useState(defaultSeo);

    const location = useLocation();

    const getSlug = (string) => {
        let array = string.split('/');
        return array.slice(-1);
    };

    const useId = location.state !== null && location.state.hasOwnProperty('id');
    const query = useId ? `posts/${location.state.id}?_embed&acf_format=standard` : `posts?slug=${getSlug(location.pathname)}&_embed&acf_format=standard`;

    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    // scroll to top on load
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        api.getData(query)
            .then((response) => {
                // console.log(response.data);
                useId ? makePost(response.data) : makePost(response.data[0]);
                setLoaded(true);
                getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

    const makePost = (data) => {
        let image, imgAlt, author, authorData;
        console.log(data);
        
        if (!data._embedded['wp:featuredmedia']) {
            image = `${defaultFeatImg.href}?as=webp`;
            imgAlt = 'Archer building';
        } else {
            image = data._embedded['wp:featuredmedia'][0].source_url;
            imgAlt = data._embedded['wp:featuredmedia'].isArray ? data._embedded['wp:featuredmedia'][0].alt_text : '';
        }
		if ( data.acf.post_custom_authors !== '' ) {
			author = data.acf.post_custom_authors[0];
			authorData = {
				ID: author.ID,
				name: author.post_title
			}
		} else {
			author = data._embedded.author[0];
			authorData = {
				ID: author.ID,
				name: author.name
			}
		}
        const postData = {
            id: data.id,
            author: authorData.name,
            externalAuthor: data.acf.external_author_name,
            authorPosition: data.author_position.replace(/<br\s*\/?>/gi, ' '),
            date: data.formatted_date,
            title: data.title.rendered,
            excerpt: data.excerpt.rendered,
            image: image,
            image_alt: imgAlt,
            sticky: data.sticky,
            slug: data.slug,
            content: data.content.rendered,
        };
        setPost(postData);
		setPostAuthor(authorData);
    };
    // const updatedTitle = data.author_position.replace(/<br\s*\/?>/gi, ' '); // Used to remove <br> brought in from WP CMS since we don't need to follow that here

    const containerClasses = 'container prose lg:prose-lg xl:prose-xl';
    return (
        <>
            <Seo title={seo.title} description={seo.description} />

            {!loaded ? (
                <div className='h-screen'></div>
            ) : (
                <Wrapper theme='light' openContactForm={props.openContactForm}>
                    {!loaded ? (
                        <div className='h-screen'></div>
                    ) : (
                        <motion.div
                            layout
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0, delay: 0 }}
                            className='bg-foreground-100 pb-20'
                        >
                            <div className='md:bg-background md:p-0 md:max-w-none container'>
                                <div className='grid md:grid-cols-2 md:min-h-[400] lg:min-h-[600]'>
                                    <motion.div
                                        className='aspect-photo md:aspect-auto bg-background mb-6 md:mb-0 max-h-[750px] overflow-hidden'
                                        initial={{ opacity: 0, y: 40 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.2, delay: 0.2 }}
                                    >
                                        <img
                                            className='w-full h-full object-cover'
                                            srcSet={`${post.image}w=2400&h=800&fit=crop 2400w,
                                                ${post.image}w=1024&h=800&fit=crop 1024w,
                                                ${post.image}&w=640&h=600&fit=crop 640w,
                                                ${post.image}&w=480&h=400&fit=crop 480w`}
                                            src={`${post.image}&w=640`}
                                            alt={post.image_alt}
                                            role={post.image_alt ? 'img' : 'presentation'}
                                            sizes='(min-width: 768px) 50vw, 100vw'
                                        ></img>
                                    </motion.div>
                                    <motion.div
                                        className='flex flex-col justify-center gap-6 md:px-4 md:py-32 lg:pl-8 xl:pl-12 md:max-w-xl'
                                        initial={{ opacity: 0, y: 40 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.2, delay: 0.3 }}
                                    >
                                        <div>
                                            <span className='block text-background md:text-foreground'>
                                                { post.externalAuthor && postAuthor.name === 'Media Publication' ? post.externalAuthor : postAuthor.name }
                                                {console.log(post)}
                                                {post.authorPosition && postAuthor.name !== 'Media Publication' && `, ${post.authorPosition}`}
                                            </span>
                                            <span className='block text-foreground-800 md:text-foreground'>
                                                {post.date}
                                            </span>
                                        </div>
                                        <h3
                                            id='post-title'
                                            className='font-normal text-4xl text-accent text-display tracking-wide'
                                        >
                                            {parse(post.title)}
                                        </h3>
                                        <div className='text-background-400 md:text-foreground'>
                                            {parse(post.excerpt)}
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                            {post ? (
                                <motion.div
                                    initial={{ opacity: 0, y: 40, rotate: 2 }}
                                    animate={{ opacity: 1, y: 0, rotate: 0 }}
                                    transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                                    layout
                                    className={`${containerClasses} pt-2 md:pt-12`}
                                >
                                    <hr className='border-foreground-200 md:hidden my-6 border' />
                                    <div
                                        style={{ '--post.image-url': `url(${bgStar})` }}
                                        className='bg-[center_top_4rem] md:bg-[post.image:var(--post.image-url)] bg-no-repeat'
                                    >
                                        {parse(post.content)}
                                    </div>
                                    { postAuthor.name !== 'Archer Staff' && postAuthor.name !== 'Media Publication' ? (
                                    <div className='py-6'>
                                        <PointPerson
                                            person={postAuthor}
                                            show_email={false}
                                            show_role
                                            show_bio
                                            show_social
                                        />
                                    </div>
                                    ) : null}
                                    <hr className='border-foreground-200 my-6 md:mb-5 border' />
                                    <div className='flex sm:flex-row flex-col sm:justify-between gap-6 not-prose'>
                                        <span className='block font-bold text-background text-base'>
                                            Share this post
                                        </span>
                                        <Share />
                                    </div>
                                </motion.div>
                            ) : (
                                <div className='min-h-screen'></div>
                            )}
                        </motion.div>
                    )}
                </Wrapper>
            )}
        </>
    );
}
