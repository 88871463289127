import React from 'react';
import Carousel from '/src/components/carousel';
import PostCard from '/src/routes/latest/components/PostCard';
import SkeletonCard from '/src/components/SkeletonCard';

const bgBlob = new URL('../../../images/postBG_blob.png?as=webp&quality=45&width=2000', import.meta.url);
export default function PostsCarousel({ posts, loaded }) {
    return (
        <div style={{'--image-url': `url(${bgBlob})`}}  className={`bg-foreground-100 py-11 md:py-16 md:bg-[image:var(--image-url)] bg-contain bg-no-repeat bg-[center_top_2rem]`}>
            <div className='container overflow-visible'>
                {/* <div className='text-xl text-foreground-800 pb-11 md:pb-16'>
                    <h2 className='text-accent uppercase'>About Archers</h2>
                    <p>We don’t do this for notoriety, but we don’t mind a good shoutout.</p>
                </div> */}
                <Carousel>
                    {
                        posts ?
                            posts.map((post, i) => {
                                return loaded ? (
                                    <PostCard post={post} key={`post-${post.id}`} />
                                ) : (
                                    <SkeletonCard lines={8} key={i} />
                                );
                            }) : null
                    }
                </Carousel>
            </div>
        </div>
    );
}
