import React from 'react';
export default function Section(props){
  return (
    <section className={
      ' pb-20 duration-300 ease-in delay-200 ' +
      (props.bg && (props.bg === 'light' ? 'bg-foreground-100' : null )) +
      (props.loaded ? ' opacity-100 translate-y-0  ' : ' opacity-0 translate-y-10 min-h-screen ')
      }>
      <div className={'container pt-16 max-w-7xl ' + (props.prose ? 'prose lg:prose-lg xl:prose-xl ' : null)}>
        {props.children}
      </div>
    </section>
  )
}