import React from 'react'
import { formLabel, inputBorder, errorLabel, inputBorderError } from './styles'
import InputWrapper from './inputWrapper'
export default function TextInput(props) {
  return (
    <InputWrapper {...props}>
        <input
          type={props.type ?? 'text'}
          name={props.name}
          id={props.name}
          className={props.hasError ? inputBorderError : inputBorder}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
        />
    </InputWrapper>
  )
}
