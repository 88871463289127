import React from 'react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import HeadshotPlaceholder from '/src/images/headshots/placeholder_woman.png';
import Social from '/src/components/Social';

export default function LeaderCard({leader, show_role, show_bio, show_email, show_social}) {
	const {id, name, title, bio, social, email} = leader;
	const defaultFeatImg = new URL('/src/images/post-default.jpg', import.meta.url);
	// const defaultFeatImg2 = new URL('/src/images/post-default-gold.jpg', import.meta.url);
	const image = leader?.image?.default ? leader.image : {
		default: `${defaultFeatImg}?as=webp`,
		// hover: `${defaultFeatImg2}?as=webp`,
	}
	const getImage = (url) => url ? `${url}&width=300` : HeadshotPlaceholder;
    const updatedTitle = title.replace(/<br\s*\/?>/gi, ' '); // Used to remove <br> brought in from WP CMS since we don't need to follow that here
    return (
        <div id={id} className='flex flex-col items-center md:items-start md:flex-row gap-x-12 justify-center text-center'>
            <a href={(show_email && email) ? `mailto:${email}` : social} target='_blank' title={(show_email && email) ? `Email ${name}` : 'LinkedIn'}>
                <figure className='relative border-4 border-foreground transition-all duration-300 rounded-full w-44 max-w-[248px] md:w-64 aspect-square overflow-hidden mb-4 md:mb-8 bg-white'>
                    <img
                        src={getImage(image.default)}
                        alt={`${name}'s headshot`}
                        className='absolute w-full h-auto rounded-full'
                    />
                </figure>
            </a>
            <div className='md:text-left md:[&>div]:justify-start'>
                <h3 className='text-3xl lg:text-4xl text-accent-500 my-2'><em>{name}</em></h3>
                {show_role && title && (
                    <p className='prose prose-lg mb-3'>{updatedTitle }</p>
                )}
                {show_bio && bio && (
                    <div
                        className='prose prose-lg max-w-lg mb-3'
                        dangerouslySetInnerHTML={{ __html: bio }} />
                )}
                {show_email && email && (
                    <a href={`mailto:${email}`} title={`Email ${name}`} className='block text-black underline mb-4'>{email}</a>
                )}
                {show_social && social && (
                    <Social
                        theme='gold'
                        selected-socials={[
                            {
                                name: 'LinkedIn',
                                url: social,
                                icon: faLinkedin,
                                text: `Hear ${name}'s thoughts on LinkedIn`,
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
}
