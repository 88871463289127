import React, { useEffect, useState } from 'react';
import Wrapper from '/src/components/wrapper';
import PageLead from '/src/components/PageLead';
import api from '/src/utils/api';
import Seo from '/src/components/Seo';
import makeSeo from '../../utils/makeSeo';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion } from 'framer-motion';

const defaultSeo = {
    title: 'Privacy Policy | Archer',
    description: 'View our privacy policy, including how Archer protects your personal information.',
};

export default function PrivacyPolicy(props) {
    const [seo, setSeo] = useState(defaultSeo);
    const [content, setContent] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            content: data.content.rendered,
            intro: {
                pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
            },
        };
        setContent(dataContent);
    };

    useEffect(() => {
        api.getData('pages/910?_embed')
            .then((response) => {
                // console.log(response.data)
                pageContent(response.data);
                setLoaded(true);
                getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
        // instantly scroll to top of page
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <>
            <Seo title={seo.title} description={seo.description} />
            {!loaded ? (
                <>
                    <div className='h-screen'></div>
                </>
            ) : (
                <Wrapper theme='light' openContactForm={props.openContactForm}>
                    {content ? (
                        <>
                            <motion.section
                                initial={{ opacity: 0, y: 40 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2, delay: 0.2 }}
                                layout
                                className='relative transparent pt-44'
                            >
                                <PageLead
                                    title={content.intro.pageTitle}
                                    titleVisible={content.intro.showTitle}
                                    subtitle={content.title}
                                    text={''}
                                />
                            </motion.section>
                            <motion.section
                                initial={{ opacity: 0, y: 40, rotate: 2 }}
                                animate={{ opacity: 1, y: 0, rotate: 0 }}
                                transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                                layout
                                className='bg-foreground-100 min-h-1/2screen'
                            >
                                <div className='container py-12'>
                                    <div className='prose mx-auto'>{parse(content.content)}</div>
                                </div>
                            </motion.section>
                        </>
                    ) : (
                        <div className='min-h-screen'></div>
                    )}
                </Wrapper>
            )}
        </>
    );
}
