export default function makePosts(data) {
	const defaultFeatImg = new URL('../images/post-default.jpg', import.meta.url);
	const defaultFeatImg2 = new URL('../images/post-default-gold.jpg', import.meta.url);

	const posts = data.map((post) => {
		// console.log(post);
		let image, imgAlt, parent;
		let image2 = post?.acf?.alt_feat_image?.url;
		let img2Alt = post?.acf?.alt_feat_image?.alt;
		let author = post?._embedded?.author;
		let externalAuthor = post?.acf?.external_author_name;
		let authorPosition = post?.author_position;

		if(image2) image2 = `${image2}&w=800&h=600&fit=min`

		if(post._embedded['wp:featuredmedia']) {
			if(post.type === 'case-study') {
				image = `${post._embedded['wp:featuredmedia'][0].source_url}fill-color=1C1F39&fit=fill&fill=solid&w=800&h=600`;
				imgAlt = post._embedded['wp:featuredmedia'][0].alt_text;
			} else {
				image = `${post.featured_image_url}&w=800&h=600&fit=min`;
				imgAlt = post._embedded['wp:featuredmedia'].isArray ? post._embedded['wp:featuredmedia'][0].alt_text : '';
			}
		} else {
			image = `${defaultFeatImg.href}?as=webp&width=800&height=600`;
			imgAlt = 'Archer building';
			image2 = `${defaultFeatImg2.href}?as=webp&width=800&height=600`;
			img2Alt = imgAlt;
		}

		if(!image2) {
			image2 = `${image}&monochrome=d3b2a4`;
			img2Alt = imgAlt;
		}

		if(author) {
			author = post._embedded.author[0].name;
		}

		if(authorPosition) {
			authorPosition = post.author_position;
		}

		if ( post.acf?.post_custom_authors ) {
			author = post.acf.post_custom_authors[0].post_title;
		}

		const path = new URL(post.link).pathname;
		let parts = path.split('/');
		parts.shift();
		parts.pop();
		parent = parts.length > 1 ? parts[0] : '';

		return {
			id: post.id,
			author: author,
			externalAuthor: externalAuthor,
			author_position: authorPosition,
			date: post.formatted_date,
			title: post.title.rendered,
			excerpt: post.excerpt.rendered,
			image: image,
			image_alt: imgAlt,
			image2: image2,
			image2_alt: img2Alt,
			sticky: post.sticky,
			slug: post.slug,
			type: post.type,
			term: post?._embedded?.['wp:term']?.[0]?.[0]?.slug,
			path: path,
			parent: parent
		};
	});
	return posts;
}
