import axios from 'axios';

export const getAPI = () => {
	if (typeof process.env.REACT_APP_WP_API !== 'undefined') {
		return process.env.REACT_APP_WP_API
	} else {
		if (process.env.NODE_ENV === 'development') {
			return process.env.REACT_APP_WP_API_DEV
		} else {
			return process.env.REACT_APP_WP_API_PROD
		}
	}	
}

// Create instance called instance
const instance = axios.create({
    baseURL: `${getAPI()}/wp-json/wp/v2/`,
    headers: {
        'content-type': 'application/json',
    },
});

export default {
    getData: (query) =>
        instance({
            method: 'GET',
            url: `${query}`,
			context: 'embed'
        }),
};