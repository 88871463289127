/**
 * Block | Content Block
 *
 * @param
 * text_columns
 * Contains an array of WYSIWYG content from ACF for each column.
 *
 * accent
 * String to represent background image to show.
 *  - star will show the asterisk star image
 */

import React from 'react';
import { motion } from 'framer-motion';

const bgStar = new URL('../../images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);
const starStyles = 'md:py-16 md:pb-6 md:before:block before:absolute before:bg-[image:var(--image-url)] before:bg-contain before:bg-no-repeat before:w-3/4 before:h-3/4 md:before:w-[425] md:before:h-[425]';
const bgPosiiton = 'before:inset-x-1/2 before:-translate-y-full before:-translate-x-1/2 before:top-1/2 before:left-1/2';

export default function Content({ text_columns, accent }) {
    return (
        <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            style={{ '--image-url': `url(${bgStar})` }}
            className={`block__content
            bg-foreground-100
            relative overflow-hidden
            py-10
            ${accent === 'star' ? `${starStyles} ${bgPosiiton}` : ''}
            `}
        >
            <div className='container relative z-10'>
                <div className={`grid lg:grid-flow-col lg:grid-cols-${text_columns.length} gap-x-10 ${text_columns.length === 1 ? 'text-center [&>div]:mx-auto [&>div]:max-w-5xl' : ''}`}>
                {text_columns && (text_columns.map((colContent, index) => {
                    return(
                    <div key={index}
                        className='prose prose-lg not-prose pt-6'
                        dangerouslySetInnerHTML={{ __html: colContent.column }}
                    />
                    )
                }))}
                </div>
            </div>
        </motion.section>
    )
}
