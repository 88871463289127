import React, { useState, useEffect } from 'react';
import Wrapper from '/src/components/wrapper';
import api from '/src/utils/api';
import Bracket from '/src/images/SVG/bracket.svg';
import parse from 'html-react-parser/dist/html-react-parser';
import { motion } from 'framer-motion';
import Seo from '/src/components/Seo';
import makeSeo from '../../utils/makeSeo';

const bgStar = new URL('/src/images/asterisk.png?as=webp&quality=45&width=436', import.meta.url);

const archerOffice = new URL('/src/images/archer-building.png?as=webp&quality=45&width=600', import.meta.url);
import LeadersSection from './components/LeadersSection';

const defaultSeo = {
    title: 'Our People | Archer',
    description: 'The people at Archer are what makes us great.',
};


export default function People(props) {
    const [loaded, setLoaded] = useState(false);
    const [heroLoaded, setHeroLoaded] = useState(false);
    const [content, setContent] = useState(null);
    const [leaderIds, setLeaderIds] = useState(null);
	const [seo, setSeo] = useState(defaultSeo);

    const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            intro: {
                pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
            },
            values: {
                header: data.acf.values_header,
                items: data.acf.values,
            },
            content: data.content.rendered,
            leaderList: data.acf.leadership_member,
        };
        setContent(dataContent);
    };

	const getSeo = (data) => {
        const newSeo = makeSeo(seo, data);
        setSeo({
            ...seo,
            title: newSeo.title,
            description: newSeo.description,
        });
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
        api.getData('pages/23?acf_format=standard')
            .then((response) => {
                // console.log(response.data);
                pageContent(response.data);
                setLeaderIds(response.data.acf.leadership_member.join());
                setLoaded(true);
				getSeo(response.data.yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

    const loadedClass = 'opacity-100 translate-y-0';
    const unloadedClass = 'opacity-0 translate-y-8';
    useEffect(() => {
        const timer = setTimeout(() => {
            setHeroLoaded(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <Seo title={seo.title} description={seo.description} />

            <Wrapper theme='light' openContactForm={props.openContactForm}>
                {!loaded ? (
                    <div className='h-screen'></div>
                ) : (
                    <motion.div
                        layout
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0, delay: 0 }}
                    >
                        <motion.section
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.2, delay: 0.2 }}
                            layout
                            className='relative transparent pt-44 pb-20'
                        >
                            {content && (
                                <div className='relative container pt-16 z-10'>
                                    <div className='grid md:grid-cols-2 gap-y-14 md:gap-8 items-start'>
                                        <div className={`duration-300 ease-in ${heroLoaded ? loadedClass : unloadedClass}`}>
                                            <div className='text-xl text-foreground leading-8 lg:leading-10'>
                                                <h1 className='text-5xl font-serif text-accent font-normal mb-5 lg:text-6xl xl:text-7xl xl:leading-[5rem] max-w-[540]'>
                                                    {content.intro.headline}
                                                </h1>
                                                {parse(content.intro.content)}
                                            </div>
                                        </div>
                                        {content.values && (
                                            <div className={`duration-200 ease-in delay-75 ${heroLoaded ? loadedClass : unloadedClass}`}>
                                                <div className='flex flex-col items-center text-center place-self-center xl:place-self-end lg:-mt-3 lg:pr-8'>
                                                    <img src={Bracket} alt='' className='rotate-90 w-max' />
                                                    <div className='py-4'>
                                                        <h2 className='text-4xl font-serif text-accent font-normal tracking-wide mb-3 md:mb-5 lg:text-6xl'>
                                                            {content.values.header}
                                                        </h2>
                                                        <ul className='[&>*:not(:last-child)]:my-2 md:[&>*:not(:last-child)]:my-4'>
                                                            {content.values.items.map((item, index) => (
                                                                <li
                                                                    key={index}
                                                                    className='text-base md:text-xl text-foreground'
                                                                >
                                                                    {item.value}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <img src={Bracket} alt='' className='rotate-270 w-max' />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <img
                                src={archerOffice}
                                alt=''
                                className='absolute left-1/2 -bottom-1/4 sm:-bottom-1/3 -translate-x-1/2 max-w-none z-0 select-none sm:max-w-full'
                                draggable='false'
                            />
                        </motion.section>

                        <motion.section
                            initial={{ opacity: 0, y: 40, rotate: 2 }}
                            animate={{ opacity: 1, y: 0, rotate: 0 }}
                            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
                            layout
                            style={{ '--image-url': `url(${bgStar})` }}
                            className='bg-foreground-100
                                relative overflow-hidden
                                md:before:block
                                before:absolute
                                before:bg-[image:var(--image-url)]
                                before:bg-contain
                                before:bg-no-repeat
                                before:inset-x-1/2
                                before:-translate-y-1/2
                                before:-translate-x-1/2
                                before:top-1/2
                                before:left-1/2
                                before:w-3/4
                                before:h-3/4
                                md:before:w-[425]
                                md:before:h-[425]
                                py-10
                                md:py-32'
                        >
                            <div className='relative container overflow-visible'>
                                <div className='prose lg:prose-lg xl:prose-xl text-center mx-auto'>
                                    {content && content.content && (
                                        <div className='[&>p]:mb-6 xl:[&>p]:leading-10'>{parse(content.content)}</div>
                                    )}
                                </div>
                            </div>
                        </motion.section>

                        {leaderIds && <LeadersSection posts={leaderIds} />}
                    </motion.div>
                )}
            </Wrapper>
        </>
    );
}
