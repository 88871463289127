import React from 'react';
import Curve from './Curve';
import Address from './Address';
import Nav from './Nav';
import Social from './Social';
import { Link } from 'react-router-dom';

export default function Footer({ theme }) {
    const logoUrl = new URL('../images/archer-logo-mark.png?as=webp&width=288', import.meta.url);
    return (
        <footer className={`${theme === 'light' && 'bg-foreground-100'} ${theme === 'gray' && 'bg-gray-200'} relative z-10`}>
            <Curve />
            <div className='bg-background text-foreground pt-4 lg:pt-2'>
                <div className='container'>
                    <div className='grid md:grid-cols-3 items-center md:items-end gap-6 text-center'>
                        {/* Logo */}
                        <div className='md:order-2'>
                            <Link to='/'>
                                <img src={logoUrl} alt='Archer logo mark' className='w-20 md:w-24 lg:w-36 mx-auto' />
                            </Link>
                        </div>
                        {/* Nav */}
                        <div className='md:order-1'>
                            <Nav id='footer-nav' footer />
                        </div>
                        {/* Address */}
                        <div className='md:order-3 md:text-right'>
                            <Address />
                        </div>
                    </div>
                </div>
                <hr className='border-foreground mt-12' />
                <div className='container py-12'>
                    <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center gap-8'>
                        <div className='sm:order-1'>
                            <Social />
                        </div>
                        <div className='text-center sm:text-left'>
                            <span>© {new Date().getFullYear()} Archer Inc.</span>
                            <span className='block sm:inline-block sm:ml-1'>All rights reserved.</span>
                            <Link className='block sm:inline-block sm:ml-1 underline hover:text-accent transition-colors' to={'/privacy-policy/'}>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
