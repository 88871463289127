export default function makeSeo(curSeo, data) {
    let { description, title } = curSeo;    
	return {
		title: getTitle(title, data),
		description: getDesc(description, data),
	}
}

const getTitle = (title, data) => {
	if (Object.hasOwn(data, 'title')) {
       return data.title;
    } else if (Object.hasOwn(data, 'og_title')) {
        return data.og_title;
    } else return title;
}

const getDesc = (desc, data) => {
	if (Object.hasOwn(data, 'description')) {
        return data.description;
    } else if (Object.hasOwn(data, 'og_description')) {
        return data.og_description;
    } else return desc;
}
