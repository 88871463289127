import React, { useEffect, useState } from 'react';
import Wrapper from '../../../components/wrapper';
import { motion } from 'framer-motion';
import Seo from '../../../components/Seo';
import makeSeo from '../../../utils/makeSeo';
import PageLead from '../../../components/PageLead';
import api from '../../../utils/api';
import Blocks from '../../../components/blocks/Blocks';

const defaultSeo = {
    title: 'Personal Services Research | Archer',
    description: 'Putting the "man" in manicure.',
};

export default function LanderPdfDownload(props) {
	const [loaded, setLoaded] = useState(false);
	const [content, setContent] = useState(null);
	const [seo, setSeo] = useState(defaultSeo);

	const pageContent = (data) => {
        let dataContent = {
            title: data.title.rendered,
            intro: {
				pageTitle: data.acf.page_title,
                showTitle: data.acf.intro_title,
                headline: data.acf.intro_headline,
                content: data.acf.intro_content,
            },
            blocks: data.acf.flexible_blocks,
        };
        setContent(dataContent);
    };

	const getSeo = (data) => {
		const newSeo = makeSeo(seo, data);
		setSeo({
			...seo,
			title: newSeo.title,
			description: newSeo.description,
		});
	};

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });

        api.getData('pages/?slug=personalservicesresearch&acf_format=standard&_embed')
            .then((response) => {
                pageContent(response.data[0]);
                setLoaded(true);
                getSeo(response.data[0].yoast_head_json);
            })
            .catch((error) => {
                console.log(error);
                setLoaded(true);
            });
    }, []);

	let countBlocks = 0;
	return (
		<>
		<Seo title={seo.title} description={seo.description} />
		<Wrapper theme='light'>
			{!loaded ? (
				<div className='h-screen'></div>
			) : (
				<motion.div
					layout
					initial={{ opacity: 0 }}
					exit={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0, delay: 0 }}
				>
					{content && (
						<motion.section
							initial={{ opacity: 0, y: 40 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.2, delay: 0.2 }}
							layout
							className='relative transparent pt-44'
						>
							<PageLead
								title={content.title}
								titleVisible={content.intro.showTitle}
								subtitle={content.intro.headline}
								text={content.intro.content}
								minHeight={400}
								noMaxWidth={true}
							/>
						</motion.section>
						)}

					{content && (content.blocks.map((block, index) => {
						// Using countBlocks to pass accent to only the first content block.
						countBlocks++;
						return(
							<Blocks
								block={block}
								key={index}
								accent={`${countBlocks <= 1 ? 'star' : ''}`}
							/>
						)
					}))}
				</motion.div>
				)}
		</Wrapper>
		</>
	)
}
