import React from 'react'
export default function viewportGet(xy) {
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth)
  const [viewportHeight, setViewportHeight] = React.useState(window.innerHeight)
  // get current viewport width
  React.useEffect(() => {
      const handleWindowResize = () => {
          setViewportWidth(window.innerWidth)
          setViewportHeight(window.innerHeight)
      }
      window.addEventListener('resize', handleWindowResize)
      return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
  if (xy === 'x') return viewportWidth
  if (xy === 'y') return viewportHeight
}