import React from 'react'
import { formLabel, inputBorder, errorLabel, inputBorderError } from './styles'
import InputWrapper from './inputWrapper'
export default function TextInput(props) {
  return (
    <InputWrapper {...props}>
        <textarea
          rows={4}
          name={props.name}
          id={props.name}
          className={props.hasError ? inputBorderError : inputBorder}
		  value={props.value}
		  onChange={props.onChange}
          defaultValue={props.defaultValue}
        />
    </InputWrapper>
  )
}
