/**
 * Block | Form
 *
 * Shows the WP Gravity form that integrates with Hubspot.
 *
 * @params
 * show_form
 * True/False as to whether or not to show the block.
 *
 * gravity_form
 * The form ID of the selected gravity form.
 * Uses the Advanced Custom Fields: Gravity Forms Add-on plugin.
 *
 * file_download
 * File to automatically download on form submission.
 */

import React from 'react';
import { motion } from 'framer-motion';
import Form from '../../routes/landers/components/Form';

export default function HubspotForm({ show_form, gravity_form, file_download }) {
    if (show_form) {
        return(
            <motion.section
            initial={{ opacity: 0, y: 40, rotate: 2 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
            layout
            className='bg-accent relative py-12'
            >
                <div className='container max-w-2xl'>
                    <Form formID={gravity_form} file={file_download} />
                </div>
            </motion.section>
        )
    }
}
