import React from 'react'
import { formLabel, inputBorder, errorLabel, inputBorderError } from './styles'
export default function InputWrapper(props) {
  return (
    <div>
      <label
        htmlFor={props.name}
        className={props.hasError ? errorLabel : formLabel}>
        {props.label}
      </label>
      <div className="w-full mt-2">
        {props.children}
      </div>

      {props.hasError && (
        <p className="mt-0 text-sm text-red-900 dark:text-red-400 text-right">{props.error}</p>
      )}
    </div>
  )
}