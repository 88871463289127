import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAnimate, usePresence, stagger, motion, AnimatePresence } from 'framer-motion';

const staggerStars = stagger(0.1, { startDelay: 0.15 });

function useStarAnimation(animating) {
    const [scope, animate] = useAnimate();
    const [isPresent, safeToRemove] = usePresence();
    //console.log(isPresent, animating);

    useEffect(() => {
        if (!isPresent) {
            const exitAnimation = async () => {
                animate(
                    '.star',
                    { opacity: 0, scale: 0.3, filter: 'blur(20px)' },
                    // animating
                    //     ? { opacity: 0, scale: 0.3, filter: 'blur(20px)' }
                    //     : { opacity: 1, scale: 1, filter: 'blur(0px)' },
                    {
                        duration: 0.15,
                        delay: animating ? staggerStars : 0,
                    },
                );
                await animate(
                    scope.current,
                    { opacity: 0 },
                    {
                        duration: 0.15,
                        delay: 0.3,
                    },
                );
                safeToRemove();
            };

            exitAnimation();
        }
        // animate(
        //     '.star',
        //     animating
        //         ? { opacity: 0, scale: 0.3, filter: 'blur(20px)' }
        //         : { opacity: 1, scale: 1, filter: 'blur(0px)' },
        //     {
        //         duration: 0.15,
        //         delay: animating ? staggerStars : 0,
        //     },
        // );
        // animate(
        //     '#archer-header-logo-stars',
        //     // animating ? { scale: 0 } : { scale: 1 },
        //     animating ? { display: 'none', y: '-100%' } : { display: 'block', y: 0 },
        //     {
        //         duration: 0.15,
        //         delay: animating ? 0.5 : 0,
        //     },
        // );

        // animate(
        //     '#archer-header-logo-text',
        //     animating ? { transform: 'translateY(-120%)' } : { transform: 'translateY(0)' },
        //     {
        //         duration: 0.3,
        //         delay: animating ? 0.15 : 0,
        //     },
        // );
    }, [animating, isPresent]);

    return scope;
}

export default function HeaderLogo({ animating }) {
    const scope = useStarAnimation(animating);
    function scrollToBottom() {
        window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
        });
    }
    return (
        <>
            <Link to='/' className={`block`} onClick={() => scrollToBottom()}>
                <AnimatePresence mode='popLayout'>
                    <motion.span layout='position' ref={scope}>
                        {!animating && (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 576 108'
                                // width="576" height="108"
                                id='archer-header-logo-stars'
                                className='hidden md:block w-full h-auto'
                            >
                                <span className='sr-only'>Archer Logo</span>
                                <path
                                    className='star'
                                    d='m192.5,41.51l5.71-8.18c.19-.27.15-.67-.08-.91l-2.13-2.14c-.23-.23-.63-.26-.9-.08l-8.19,5.77c-.53.37-1.03.15-1.1-.5l-1.56-14.51c-.04-.34-.35-.63-.7-.63h-3.05c-.35,0-.66.28-.7.63l-1.56,14.51c-.07.65-.57.88-1.1.5l-8.19-5.77c-.27-.19-.67-.15-.9.08l-2.13,2.14c-.23.23-.26.63-.08.91l5.71,8.18c.37.53.15,1.04-.5,1.11l-14.39,1.63c-.34.04-.62.35-.62.7v3.08c0,.35.28.66.63.7l14.48,1.55c.65.07.88.57.5,1.11l-5.79,8.26c-.19.27-.15.67.08.91l2.13,2.14c.23.23.63.26.9.08l8.19-5.77c.53-.37,1.03-.15,1.1.5l1.56,14.51c.04.34.35.63.7.63h3.05c.35,0,.66-.28.7-.63l1.56-14.51c.07-.65.56-.89,1.1-.5,2.15,1.52,4.77,3.37,8.19,5.77.27.19.67.15.9-.08l2.13-2.14c.23-.23.26-.63.08-.91l-5.71-8.26c-.37-.53-.15-1.04.5-1.11l14.38-1.55c.34-.04.63-.35.63-.7v-3.08c0-.34-.28-.66-.62-.7l-14.39-1.63c-.65-.07-.87-.57-.5-1.11h-.01Z'
                                    fill='#b57f68'
                                />
                                <path
                                    className='star'
                                    d='m298.48,31.35l5.71-8.18c.19-.27.15-.67-.08-.91l-2.13-2.14c-.23-.23-.63-.26-.9-.08l-8.19,5.77c-.53.37-1.03.15-1.1-.5l-1.56-14.51c-.04-.34-.35-.63-.7-.63h-3.05c-.35,0-.66.28-.7.63l-1.56,14.51c-.07.65-.57.88-1.1.5l-8.19-5.77c-.27-.19-.67-.15-.9.08l-2.13,2.14c-.23.23-.26.63-.08.91l5.71,8.18c.37.53.15,1.04-.5,1.11l-14.39,1.63c-.34.04-.62.35-.62.7v3.08c0,.35.28.66.63.7l14.48,1.55c.65.07.88.57.5,1.11l-5.79,8.26c-.19.27-.15.67.08.91l2.13,2.14c.23.23.63.26.9.08l8.19-5.77c.53-.37,1.03-.15,1.1.5l1.56,14.51c.04.34.35.63.7.63h3.05c.35,0,.66-.28.7-.63l1.56-14.51c.07-.65.56-.89,1.1-.5,2.15,1.52,4.77,3.37,8.19,5.77.27.19.67.15.9-.08l2.13-2.14c.23-.23.26-.63.08-.91l-5.71-8.26c-.37-.53-.15-1.04.5-1.11l14.38-1.55c.34-.04.63-.35.63-.7v-3.08c0-.34-.28-.66-.62-.7l-14.39-1.63c-.65-.07-.87-.57-.5-1.11h-.01Z'
                                    fill='#b57f68'
                                />
                                <path
                                    className='star'
                                    d='m404.46,21.19l5.71-8.18c.19-.27.15-.67-.08-.91l-2.13-2.14c-.23-.23-.63-.26-.9-.08l-8.19,5.77c-.53.37-1.03.15-1.1-.5l-1.56-14.51c-.04-.34-.35-.63-.7-.63h-3.05c-.35,0-.66.28-.7.63l-1.56,14.51c-.07.65-.57.88-1.1.5l-8.19-5.77c-.27-.19-.67-.15-.9.08l-2.13,2.14c-.23.23-.26.63-.08.91l5.71,8.18c.37.53.15,1.04-.5,1.11l-14.39,1.63c-.34.04-.62.35-.62.7v3.08c0,.35.28.66.63.7l14.48,1.55c.65.07.88.57.5,1.11l-5.79,8.26c-.19.27-.15.67.08.91l2.13,2.14c.23.23.63.26.9.08l8.19-5.77c.53-.37,1.03-.15,1.1.5l1.56,14.51c.04.34.35.63.7.63h3.05c.35,0,.66-.28.7-.63l1.56-14.51c.07-.65.56-.89,1.1-.5,2.15,1.52,4.77,3.37,8.19,5.77.27.19.67.15.9-.08l2.13-2.14c.23-.23.26-.63.08-.91l-5.71-8.26c-.37-.53-.15-1.04.5-1.11l14.38-1.55c.34-.04.63-.35.63-.7v-3.08c0-.34-.28-.66-.62-.7l-14.39-1.63c-.65-.07-.87-.57-.5-1.11h-.01Z'
                                    fill='#b57f68'
                                />
                            </svg>
                        )}
                    </motion.span>
                </AnimatePresence>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    // width='576' height='73'
                    ref={scope}
                    viewBox='0 0 576 73'
                    id='archer-header-logo-text'
                >
                    <path
                        d='m414.42,3.46h60.11c.81,0,1.46.67,1.46,1.51v8.59c0,.83-.65,1.51-1.46,1.51h-60.11c-.81,0-1.46-.67-1.46-1.51V4.97c0-.83.65-1.51,1.46-1.51Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m414.42,59.54h60.11c.81,0,1.46.67,1.46,1.51v8.59c0,.83-.65,1.51-1.46,1.51h-60.11c-.81,0-1.46-.67-1.46-1.51v-8.59c0-.83.65-1.51,1.46-1.51Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m414.42,31.5h39.52c.81,0,1.46.67,1.46,1.51v8.59c0,.83-.65,1.51-1.46,1.51h-39.52c-.81,0-1.46-.67-1.46-1.51v-8.59c0-.83.65-1.51,1.46-1.51Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m16.6,69.92l27.88-41.33c.58-.87,1.82-.87,2.4,0l27.88,41.33c.51.76,1.36,1.22,2.25,1.22h12.89c1.18,0,1.87-1.37,1.2-2.36L46.88,3.19c-.58-.87-1.82-.87-2.4,0L.26,68.78c-.67,1,.02,2.36,1.2,2.36h12.88c.9,0,1.74-.45,2.25-1.22Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m250.83,13.28c8.9,0,17.11,3.61,22.33,9.75.44.52,1.15.68,1.75.38l9.19-4.69c.84-.42,1.09-1.56.49-2.29-7.78-9.52-20.19-15.12-33.78-15.12-22.85,0-41.45,16.15-41.45,36s18.6,36,41.45,36c13.58,0,26-5.61,33.78-15.12.6-.73.34-1.87-.49-2.29l-9.19-4.69c-.6-.31-1.31-.14-1.75.38-5.23,6.14-13.43,9.75-22.33,9.75-14.84,0-27.02-10.03-27.74-22.83-.02-.39-.04-.78-.04-1.19s.01-.79.04-1.19c.72-12.81,12.9-22.83,27.74-22.83v-.02Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m322.82,3.46h-10.84c-.81,0-1.46.67-1.46,1.51v64.67c0,.84.65,1.51,1.46,1.51h10.84c.81,0,1.46-.67,1.46-1.51v-25.03c0-.84.65-1.51,1.46-1.51h27.56c.81,0,1.46-.67,1.46-1.51v-9.19c0-.84-.65-1.51-1.46-1.51h-27.56c-.81,0-1.46-.67-1.46-1.51V4.97c0-.84-.65-1.51-1.46-1.51h0Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m373.24,3.46h10.84c.81,0,1.46.67,1.46,1.51v64.67c0,.83-.65,1.51-1.46,1.51h-10.84c-.81,0-1.46-.67-1.46-1.51V4.97c0-.83.65-1.51,1.46-1.51Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m187.4,25.52c0-13.6-10.74-22.05-28.04-22.05h-44.18c-.81,0-1.46.67-1.46,1.51v64.67c0,.84.65,1.51,1.46,1.51h10.74c.81,0,1.46-.68,1.46-1.51l-.09-53.06c0-.84.65-1.51,1.46-1.51h29.74c6.38,0,14.86,1.09,14.86,10.55s-8.48,10.55-14.86,10.55h-11.78c-1.24,0-1.92,1.5-1.12,2.47l25.83,31.5c.52.64,1.29,1,2.1,1h11.73c1.25,0,1.92-1.52,1.1-2.5l-16.76-19.9c-.71-.85-.3-2.17.75-2.44,5.19-1.39,17.06-6.17,17.06-20.78v-.02Z'
                        fill='#bebec6'
                    />
                    <path
                        d='m575.99,25.52c0-13.6-10.74-22.05-28.04-22.05h-44.18c-.81,0-1.46.67-1.46,1.51v64.67c0,.84.65,1.51,1.46,1.51h10.74c.81,0,1.46-.68,1.46-1.51l-.09-53.06c0-.84.65-1.51,1.46-1.51h29.74c6.38,0,14.86,1.09,14.86,10.55s-8.48,10.55-14.86,10.55h-11.78c-1.24,0-1.92,1.5-1.12,2.47l25.83,31.5c.52.64,1.29,1,2.1,1h11.73c1.25,0,1.92-1.52,1.1-2.5l-16.76-19.9c-.71-.85-.3-2.17.75-2.44,5.19-1.39,17.06-6.17,17.06-20.78v-.02Z'
                        fill='#bebec6'
                    />
                </svg>
            </Link>
        </>
    );
}
